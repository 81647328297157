import { useEffect, useRef } from 'react';
import {
  Bar,
  Brush,
  CartesianGrid,
  ComposedChart,
  Label,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useRecoilState, useRecoilValue } from 'recoil';
import { alpha, useTheme } from '@mui/material/styles';
import {
  isMobileState,
  newModelPcTypeState,
  putCallImpactInitialDataState,
  putCallImpactZoomConfigState,
  screenWidthState,
  selectedEquityLevelsState,
  selectedEquityState,
} from '../../../states';
import { GammaImpact, NewModelPcType } from '../../../types';
import {
  calculateTextWidth,
  formatAsCurrency,
  getZoomConfigRefArea,
  shadeColor,
  updateBrushZoomConfig,
  formatAsCompactNumberCallback,
} from '../../../util';
import { ColorMode } from '../../../theme';
import { capitalize } from 'lodash';
import {
  USING_NEW_MODEL,
  DEFAULT_CHART_MARGINS,
  DEFAULT_Y_AXIS_STYLES,
  DEFAULT_Y2_AXIS_STYLES,
} from '../../../config';
import useBrushZoom from '../../../hooks/useBrushZoom';
import ChartWatermarkContainer from '../../shared/ChartWatermarkContainer';

type PutCallImpactGraphProps = {
  showKeyLevels: boolean;
};

const getTotalGamma = (gi: GammaImpact) =>
  Math.abs(gi.call_gnot ?? 0) + Math.abs(gi.put_gnot ?? 0);

export const PutCallImpactGraph = ({
  showKeyLevels,
}: PutCallImpactGraphProps) => {
  const isMobile = useRecoilValue(isMobileState);
  const ref = useRef<HTMLDivElement | null>(null);
  const selectedEquity = useRecoilValue(selectedEquityState);
  const levels = useRecoilValue(selectedEquityLevelsState);
  const screenWidth = useRecoilValue(screenWidthState);
  const newModelPcType = useRecoilValue(newModelPcTypeState);

  const [zoomConfig, setZoomConfig] = useRecoilState(
    putCallImpactZoomConfigState,
  );
  const [initialData, setInitialData] = useRecoilState(
    putCallImpactInitialDataState,
  );

  const { zoomChartConfig } = useBrushZoom<GammaImpact>(
    zoomConfig,
    setZoomConfig,
    'strike',
    initialData,
  );

  const theme = useTheme();
  const fieldColorMapping = theme.palette.equityHub.fieldColorMapping;

  const getInitialZoomIdx = (data: GammaImpact[]) => {
    const totGammas = data.map(getTotalGamma);
    const maxGamma: number = Math.max(...totGammas);
    const threshold = maxGamma * 0.05; // Use 5% threshold of max gamma as filtere
    let left = 0;
    while (left < totGammas.length && totGammas[left] < threshold) {
      left++;
    }

    let right = totGammas.length - 1;
    while (right > 0 && totGammas[right] < threshold) {
      right--;
    }
    return { left, right };
  };

  useEffect(() => {
    if (!selectedEquity) {
      return;
    }
    const pgList = JSON.parse(selectedEquity.pg_list);
    const cgList = JSON.parse(selectedEquity.cg_list);
    const strikeList = JSON.parse(selectedEquity.strike_list);

    const gammas: { [strike: number]: GammaImpact } = Object.fromEntries(
      strikeList.map((strike: number, i: number) => [
        strike,
        { cg: cgList[i], pg: pgList[i] },
      ]),
    );

    const strikeCallInfo = strikeKeyInfo(true);
    const strikePutInfo = strikeKeyInfo(false);

    const callValList = JSON.parse(
      (selectedEquity as any)[strikeCallInfo.listKey],
    );
    const callStrikeList = JSON.parse(selectedEquity.call_strikes_list_absg);
    const putValList = JSON.parse(
      (selectedEquity as any)[strikePutInfo.listKey],
    );

    callStrikeList.forEach(
      (strike: number, i: number) =>
        (gammas[strike] = {
          ...gammas[strike],
          [strikeCallInfo.dataKey]: -callValList[i],
          [strikePutInfo.dataKey]: putValList[i],
        }),
    );

    const transformedData = Object.entries(gammas).map(([strike, values]) => ({
      strike: Number(strike),
      ...values,
    }));

    setInitialData(transformedData.slice().sort((a, b) => a.strike - b.strike));
  }, [selectedEquity, setInitialData, newModelPcType]);

  useEffect(() => {
    const initialIndices = getInitialZoomIdx(initialData);
    updateBrushZoomConfig(
      zoomConfig,
      initialData,
      setZoomConfig,
      initialIndices.right,
      initialIndices.left,
      true,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEquity, initialData]);

  const getReferenceLines = () => {
    let referenceLines = null;
    if (levels != null && showKeyLevels) {
      // show longer level names on top to make it easier to read
      const sortedLevels = [...levels].sort((a, b) =>
        a.name.length < b.name.length ? 1 : -1,
      );
      referenceLines = sortedLevels.map(({ field, value, name }, i) => {
        const values = initialData
          .map((d) => d.strike ?? -1)
          .filter((d) => d !== -1);
        const min = Math.min(...values);
        const max = Math.max(...values);
        const xAxisTicks = max - min;
        // 0.7 is default content width in dashboardLayout.tsx
        const chartWidth = ref.current?.offsetWidth ?? screenWidth * 0.7;
        const pxPerTick = chartWidth / xAxisTicks;
        const pxFromMax = (max - value) * pxPerTick;
        // if window is taller, add more spacing
        // if there are more than 5 levels to add, decrease spacing proportionally since youll need it
        // note window.innerHeight does not handle resizing after render, youd need to refresh
        const levelsCountRatio = Math.min(1, 5 / levels.length);
        const y =
          20 +
          i *
            Math.max(((55 * window.innerHeight) / 1000) * levelsCountRatio, 20);
        const text = `${name} $${value}`;
        const width = calculateTextWidth(text);
        const x = width > pxFromMax ? -1 * width * 0.82 : 1;
        const stroke =
          fieldColorMapping[
            field.split(',')[0] as keyof typeof fieldColorMapping
          ];
        const textShadowColor = shadeColor(
          stroke,
          theme.colorMode === ColorMode.LIGHT ? -60 : -90,
        );
        return (
          <ReferenceLine
            x={value}
            yAxisId="line"
            key={`put-call-reference-line-${field}`}
            stroke={stroke}
            strokeWidth={2}
            label={
              <Label
                position={{ x: x, y: y }}
                value={text}
                angle={0}
                height={20}
                style={{
                  textAnchor: 'start',
                  fill: stroke,
                  fontSize: isMobile ? 10 : 13,
                  fontWeight: '900',
                  textShadow: `-1px -1px 0 ${textShadowColor}, 1px -1px 0 ${textShadowColor}, -1px 1px 0 ${textShadowColor}, 1px 1px 0 ${textShadowColor}`,
                }}
              />
            }
            isFront
          />
        );
      });
    }

    return referenceLines;
  };

  const strikeKeyInfo = (call: boolean) => {
    const direction = call ? 'call' : 'put';

    if (!USING_NEW_MODEL) {
      return {
        dataKey: `${direction}_gnot`,
        title: `Strike ${capitalize(direction)} Gamma`,
        listKey: `${direction}_gnot_list_absg`,
      };
    }

    if (newModelPcType === NewModelPcType.OI) {
      return {
        dataKey: `${direction}_oi`,
        title: `Strike ${capitalize(direction)} OI`,
        listKey: `${direction}_oi`,
      };
    } else if (newModelPcType === NewModelPcType.ABS_GAMMA) {
      return {
        dataKey: `${direction}_gamma_abs`,
        title: `Strike ${capitalize(direction)} Gamma`,
        listKey: `${direction}_gamma_abs`,
      };
    } else {
      return {
        dataKey: `${direction}_gnot`,
        title: `Strike ${capitalize(direction)} Synthetic Gamma`,
        listKey: `${direction}_gnot_list_absg`,
      };
    }
  };

  return (
    <ChartWatermarkContainer
      ref={ref}
      size={15}
      offsetX={50}
      offsetY={50}
      sym={selectedEquity?.sym}
      symStyles={{
        right: '75px',
      }}
    >
      <ResponsiveContainer>
        <ComposedChart
          margin={{ ...DEFAULT_CHART_MARGINS }}
          stackOffset="sign"
          {...zoomChartConfig}
        >
          <CartesianGrid
            strokeDasharray={
              theme.colorMode === ColorMode.LIGHT ? '2 8' : '0.3 8'
            }
          />
          <XAxis
            dataKey="strike"
            allowDataOverflow
            domain={['dataMin', 'dataMax']}
            tick={{ fontSize: 11 }}
            label={{
              value: 'Strike',
              fontSize: 12,
              offset: 3,
              position: 'insideBottom',
              fontWeight: 600,
            }}
            tickCount={10}
            type="number"
            interval="preserveStartEnd"
          />
          <Brush
            dataKey="strike"
            startIndex={zoomConfig.leftIdx}
            endIndex={zoomConfig.rightIdx}
            onChange={(brushIndices: any) =>
              setZoomConfig((prev) => ({
                ...prev,
                leftIdx: brushIndices.startIndex,
                rightIdx: brushIndices.endIndex,
              }))
            }
            height={25}
            travellerWidth={15}
            stroke={theme.palette.gray}
            fill={theme.palette.background.paper}
            alwaysShowText
          />
          <YAxis
            allowDataOverflow
            domain={['dataMin', 'dataMax']}
            tick={{ fontSize: 11 }}
            tickFormatter={formatAsCompactNumberCallback}
            yAxisId="line"
            label={{
              value: 'Est. Gamma (line)',
              ...DEFAULT_Y_AXIS_STYLES,
            }}
          />
          <YAxis
            allowDataOverflow
            domain={['dataMin', 'dataMin']}
            tick={{ fontSize: 11 }}
            yAxisId="bar"
            orientation="right"
            tickFormatter={formatAsCompactNumberCallback}
            label={{
              value: 'Est. Gamma Notional (bar)',
              ...DEFAULT_Y2_AXIS_STYLES,
            }}
          />
          <Tooltip
            formatter={formatAsCompactNumberCallback}
            labelFormatter={(value) => `Strike: ${formatAsCurrency(value)}`}
            itemStyle={{ fontSize: '11px' }}
            contentStyle={{
              color: theme.palette.text.primary,
              border: 'none',
              backgroundColor: theme.palette.background.paper,
              boxShadow: theme.palette.shadows.paperBoxShadow,
            }}
            separator=": "
          />

          <Line
            type="basis"
            dataKey="pg"
            name={USING_NEW_MODEL ? 'Next Exp GEX' : 'Cumulative Put Gamma'}
            strokeWidth={2}
            stroke={
              USING_NEW_MODEL
                ? theme.palette.equityHub.putCallImpact.nextExpGex
                : theme.palette.core.put
            }
            dot={false}
            yAxisId={'line'}
            connectNulls
          />
          <Line
            type="basis"
            dataKey="cg"
            name={USING_NEW_MODEL ? 'All Exp GEX' : 'Cumulative Call Gamma'}
            strokeWidth={2}
            stroke={
              USING_NEW_MODEL
                ? theme.palette.equityHub.putCallImpact.allExpGex
                : theme.palette.core.call
            }
            dot={false}
            yAxisId={'line'}
            connectNulls
          />
          <Bar
            dataKey={strikeKeyInfo(false).dataKey}
            stackId="gnot"
            name={strikeKeyInfo(false).title}
            barSize={20}
            fill={alpha(theme.palette.core.put, 0.75)}
            yAxisId="bar"
          />
          <Bar
            dataKey={strikeKeyInfo(true).dataKey}
            stackId="gnot"
            name={strikeKeyInfo(true).title}
            barSize={20}
            fill={alpha(theme.palette.core.call, 0.75)}
            yAxisId="bar"
          />
          {getReferenceLines()}
          {getZoomConfigRefArea(zoomConfig, 'line')}
        </ComposedChart>
      </ResponsiveContainer>
    </ChartWatermarkContainer>
  );
};
