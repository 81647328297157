import { Box } from '@mui/material';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import { useRecoilState } from 'recoil';
import { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
  Brush,
} from 'recharts';
import { ErrorContent, Loader, ZoomOutButton } from '../../shared';
import {
  IndicesContentType,
  OptionsRiskReversal,
  SymSelectorSettings,
} from '../../../types';
import {
  formatAsCompactNumberCallback,
  getUtcYMD,
  getZoomConfigRefArea,
} from '../../../util';
import useOptionsRiskReversal from '../../../hooks/indices/useOptionsRiskReversal';
import {
  DEFAULT_BRUSH_ZOOM_CONFIG,
  DEFAULT_CHART_MARGINS,
  DEFAULT_X_AXIS_STYLES,
  DEFAULT_Y_AXIS_STYLES,
} from '../../../config';
import useBrushZoom from '../../../hooks/useBrushZoom';
import { orrChartZoomConfigState, orrInitialDataState } from '../../../states';
import { SUPPRESSED_SYMS_OPTIONS_RISK_REVERSAL } from '../../../util/indices';
import ChartWatermarkContainer from '../../shared/ChartWatermarkContainer';
import { IndicesHeader } from '../shared';

interface OptionsRiskReversalChartProps {
  selectedSym: string;
  chartStyleOverrides?: React.CSSProperties;
  containerStyleOverrides?: SxProps<Theme>;
  symSelectorSettings?: SymSelectorSettings;
}

export const OptionsRiskReversalChart = ({
  selectedSym,
  chartStyleOverrides,
  containerStyleOverrides,
  symSelectorSettings,
}: OptionsRiskReversalChartProps) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { getOptionsRiskReversal } = useOptionsRiskReversal();
  const [zoomConfig, setZoomConfig] = useRecoilState(orrChartZoomConfigState);
  const [initialData, setInitialData] = useRecoilState(orrInitialDataState);

  const { zoomChartConfig } = useBrushZoom<OptionsRiskReversal>(
    zoomConfig,
    setZoomConfig,
    'epoch_millis',
    initialData,
  );

  useEffect(() => {
    async function generateOCC() {
      setIsLoading(true);
      let data = await getOptionsRiskReversal();
      const transformedData = data.map(
        (d: { rr: any; trade_date: string }) => ({
          rr: d.rr,
          epoch_millis: dayjs(d.trade_date).valueOf(),
        }),
      );
      setInitialData(transformedData);
      setZoomConfig((prev) => ({ ...prev, data: transformedData }));
      setIsLoading(false);
    }
    generateOCC();
  }, [getOptionsRiskReversal, setZoomConfig, setIsLoading, setInitialData]);

  if (zoomConfig.data == null && !isLoading) {
    return <ErrorContent />;
  }

  const suppress = SUPPRESSED_SYMS_OPTIONS_RISK_REVERSAL.has(selectedSym);

  return suppress ? null : (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        opacity: suppress ? '0.2' : '1',
        gap: '8px',
        ...containerStyleOverrides,
      }}
    >
      <IndicesHeader
        symbol={selectedSym}
        symSelectorSettings={symSelectorSettings}
        type={IndicesContentType.OPTIONS_RISK_REVERSAL_CHART}
        title="Options Risk Reversal"
        expandable
        customController={
          <ZoomOutButton
            zoomConfig={zoomConfig}
            setZoomConfig={setZoomConfig}
            initialData={initialData}
            overrideDefault={{
              leftIdx: DEFAULT_BRUSH_ZOOM_CONFIG.leftIdx,
              rightIdx: initialData.length - 1,
            }}
          />
        }
      />
      <Loader isLoading={isLoading} />
      {!isLoading && (
        <ChartWatermarkContainer
          ref={ref}
          size={15}
          offsetX={55}
          offsetY={40}
          style={{ ...chartStyleOverrides }}
        >
          <ResponsiveContainer>
            <AreaChart
              margin={{ ...DEFAULT_CHART_MARGINS, left: 10, right: 75 }}
              {...zoomChartConfig}
            >
              <CartesianGrid
                strokeDasharray="1 10"
                stroke={theme.palette.gray}
              />
              <XAxis
                allowDataOverflow
                label={{
                  ...DEFAULT_X_AXIS_STYLES,
                  value: 'Date',
                  offset: 3,
                }}
                dataKey="epoch_millis"
                type="number"
                domain={['dataMin', 'dataMax']}
                tick={{ fontSize: 10 }}
                tickFormatter={getUtcYMD}
                tickCount={15}
              />
              <Brush
                dataKey="epoch_millis"
                tickFormatter={getUtcYMD}
                startIndex={zoomConfig.leftIdx}
                endIndex={zoomConfig.rightIdx}
                onChange={(brushIndices: any) =>
                  setZoomConfig((prev) => ({
                    ...prev,
                    leftIdx: brushIndices.startIndex,
                    rightIdx: brushIndices.endIndex,
                  }))
                }
                height={20}
                travellerWidth={15}
                stroke={theme.palette.gray}
                fill={theme.palette.background.paper}
                alwaysShowText
              />
              <YAxis
                allowDataOverflow
                tickFormatter={formatAsCompactNumberCallback}
                tick={{ fontSize: 11 }}
                domain={['dataMin', 0]}
                type="number"
                label={{
                  value: 'Risk Reversal',
                  ...DEFAULT_Y_AXIS_STYLES,
                }}
              />
              <Tooltip
                formatter={(v: string) => parseFloat(v).toLocaleString()}
                labelFormatter={getUtcYMD}
                itemStyle={{ fontSize: '11px' }}
                contentStyle={{
                  color: theme.palette.text.primary,
                  border: 'none',
                  backgroundColor: theme.palette.background.paper,
                  boxShadow: theme.palette.shadows.paperBoxShadow,
                }}
                separator=": "
              />
              <Area
                dataKey="rr"
                name="Risk Reversal"
                type="monotone"
                dot={false}
                stroke={theme.palette.secondary.main}
                fill={theme.palette.secondary.main}
                connectNulls
              />
              {getZoomConfigRefArea(zoomConfig)}
            </AreaChart>
          </ResponsiveContainer>
        </ChartWatermarkContainer>
      )}
    </Box>
  );
};
