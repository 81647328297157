import {
  Box,
  Button,
  IconButton,
  Link as MaterialLink,
  Stack,
} from '@mui/material';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import MenuIcon from '@mui/icons-material/Menu';
import HelpIcon from '@mui/icons-material/Help';
import { alpha, styled, useTheme } from '@mui/material/styles';
import { AutoComplete } from './Autocomplete';
import { AlertsDropdown } from './alerts';
import { useLocation } from 'react-router-dom';
import CampaignIcon from '@mui/icons-material/Campaign';
import { useMemo } from 'react';
import {
  isBloomberg,
  readableProductType,
  NON_PRODUCT_PAGES,
  isZerohedge,
  showSubscribeButton,
} from '../../util';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  currentProductTypeState,
  isMobileState,
  isSideDrawerExpandedState,
  userDashAccessState,
  userDetailsState,
  userIsLoggedInState,
} from '../../states';
import { DashAccess, hasDashAccess, ProductType } from '../../types';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { SGTooltip } from './SGTooltip';
import PageTitle from './PageTitle';
import RefreshIcon from '@mui/icons-material/Refresh';
import useRefresh from '../../hooks/useRefresh';
import { TickersSnapshot } from '../home/TickersSnapshot';
import { UsingProductButton } from './UsingProductButton';
import { Center } from '../shared/Center';
import useAuth from '../../hooks/auth/useAuth';
import UserNotificationsDropdown from '../../pages/components/Notifications/UserNotificationsDropdown';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '10px',
  backgroundColor: '#1E1F23',
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.1),
  },
  borderColor: `rgba(255, 255, 255, 0.05)`,
  display: 'flex',
  flexDirection: 'row',
  textAlign: 'left',
  alignItems: 'center',
}));

export const Header = () => {
  const theme = useTheme();
  const isLoggedIn = useRecoilValue(userIsLoggedInState);
  const isMobile = useRecoilValue(isMobileState);
  const [isMobileDrawerExpanded, setIsMobileDrawerExpanded] = useRecoilState(
    isSideDrawerExpandedState,
  );
  const userDashAccess = useRecoilValue(userDashAccessState);
  const currentProductType = useRecoilValue(currentProductTypeState);
  const userDetails = useRecoilValue(userDetailsState);
  const { hasAccessToProduct } = useAuth();
  const hasAccess = hasAccessToProduct(currentProductType);
  const showSearch = useMemo(() => {
    return new Set<ProductType | null>([
      ProductType.IMPLIED_VOL,
      ProductType.HOME,
      ProductType.HIRO,
      ProductType.SCANNERS,
      ProductType.EQUITYHUB,
      ProductType.INTERNAL_OPEN_INTEREST,
    ]).has(currentProductType);
  }, [currentProductType]);
  const { triggerRefresh } = useRefresh();

  const location = useLocation();

  const pageHeadertitle: string | null = useMemo(() => {
    return (
      readableProductType(currentProductType) ??
      NON_PRODUCT_PAGES[location.pathname]
    );
  }, [currentProductType, location]);

  if (userDetails == null && showSubscribeButton()) {
    return (
      <Box sx={{ textAlign: 'center', margin: '10px' }}>
        <Button
          color="primary"
          variant="contained"
          sx={{
            textTransform: 'none',
            color: 'black',
            fontSize: isMobile ? '18px' : '24px',
            py: '6px',
            px: '24px',
            margin: 'auto',
          }}
          onClick={() =>
            window.open(
              'https://spotgamma.com/subscribe-to-spotgamma/',
              '_blank',
            )
          }
        >
          Want full access? Subscribe Now
        </Button>
      </Box>
    );
  }

  const search = showSearch && (
    <Search
      sx={{
        marginX: isMobile ? 0 : '10px',
        width: isMobile ? '100%' : '125px',
      }}
    >
      <AutoComplete
        inputStyle={{
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.text.primary,
          width: '100%',
          borderRadius: '10px',
        }}
        optionsStyle={{
          color: theme.palette.text.primary,
          boxShadow: theme.palette.shadows.default,
          backgroundColor: theme.palette.background.paper,
        }}
      />
    </Search>
  );

  return (
    <Stack
      gap={1}
      sx={{
        backgroundColor: theme.palette.background.default,
        pt: '25px',
        pb: '15px',
        pr: '7px',
        pl: '7px',
        width: '100%',
      }}
    >
      <Stack
        direction="row"
        gap={1}
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {isMobile && (
          <IconButton
            color="inherit"
            aria-label="open sidebar"
            onClick={() => setIsMobileDrawerExpanded(!isMobileDrawerExpanded)}
          >
            <MenuIcon fontSize="large" />
          </IconButton>
        )}

        <Stack
          direction="row"
          sx={{
            alignItems: 'center',
          }}
        >
          {pageHeadertitle && !isMobile && (
            <PageTitle title={pageHeadertitle} />
          )}

          {search}
        </Stack>

        <Stack
          direction="row"
          gap={3}
          sx={{
            marginRight: '10px',
            alignItems: 'center',
            flexGrow: 1,
          }}
        >
          <Box flexGrow={1} />
          {/* there is also another render of TickersSnapshot below this one, for mobile users */}
          {!isMobile && <TickersSnapshot />}
          {!isMobile && <UsingProductButton />}

          {isBloomberg() && (
            <IconButton
              onClick={triggerRefresh}
              color="primary"
              aria-label="refresh page"
              sx={{
                textTransform: 'none',
                height: '30px',
                width: '30px',
                marginTop: '-4px',
              }}
            >
              <SGTooltip title={'Refresh page'}>
                <RefreshIcon />
              </SGTooltip>
            </IconButton>
          )}
          <SGTooltip title="Chart functionality courtesy of TradingView Lightweight Charts Copyright (с) 2022 TradingView, Inc">
            <ReceiptIcon
              style={{
                fontSize: isZerohedge() ? '14px' : '20px',
                color: theme.palette.gray,
              }}
            />
          </SGTooltip>
          <MaterialLink
            href="https://support.spotgamma.com/hc/en-us"
            target="_blank"
          >
            <SGTooltip title="FAQ">
              <HelpIcon
                style={{ fontSize: '20px', color: theme.palette.gray }}
              />
            </SGTooltip>
          </MaterialLink>
          <MaterialLink href="/admin">
            <SGTooltip title="Admin">
              <AdminPanelSettingsIcon
                style={{
                  fontSize: '20px',
                  color: theme.palette.gray,
                }}
              />
            </SGTooltip>
          </MaterialLink>

          {hasDashAccess(userDashAccess, DashAccess.BETA) && isLoggedIn && (
            <UserNotificationsDropdown>
              <SGTooltip title="Notifications">
                <IconButton>
                  <CampaignIcon
                    style={{
                      fontSize: '20px',
                      color: theme.palette.gray,
                    }}
                  />
                </IconButton>
              </SGTooltip>
            </UserNotificationsDropdown>
          )}

          <AlertsDropdown />
        </Stack>
      </Stack>

      {isMobile && (
        <Stack sx={{ marginTop: '5px', gap: '4px', alignItems: 'center' }}>
          <TickersSnapshot />
        </Stack>
      )}

      {isMobile && (
        <Center vertical={false}>
          <Stack direction="row" gap="15px" alignItems="center">
            {hasAccess && pageHeadertitle && (
              <PageTitle title={pageHeadertitle} />
            )}
            <UsingProductButton />
          </Stack>
        </Center>
      )}
    </Stack>
  );
};
