import { useSearchParams } from 'react-router-dom';
import { ProductType, Scanner } from '../../types';
import { updateSearch } from '../../util';
import { useCallback, useEffect, useMemo } from 'react';
import { RecoilState, useRecoilState } from 'recoil';
import useAuth from '../auth/useAuth';

const SCANNER_PARAM = 'active_scanner';

const useActiveScanner = (
  activeScannerState: RecoilState<Scanner | undefined>,
) => {
  const [searchParams, setSearch] = useSearchParams();
  const { hasAccessToProduct } = useAuth();
  const hasScannersAccess = hasAccessToProduct(ProductType.SCANNERS);
  const [currentScanner, setCurrentScanner] =
    useRecoilState(activeScannerState);

  const activeScanner = useMemo(
    () => (searchParams.get(SCANNER_PARAM) as Scanner) ?? undefined,
    [searchParams],
  );

  const deleteActiveScannerParam = useCallback(() => {
    setSearch((params) => {
      params.delete(SCANNER_PARAM);
      return params;
    });
  }, [setSearch]);

  const deleteActiveScanner = useCallback(() => {
    setCurrentScanner(undefined);
    deleteActiveScannerParam();
  }, [deleteActiveScannerParam, setCurrentScanner]);

  useEffect(() => {
    if (!activeScanner) {
      setActiveScanner(currentScanner);
    }
  }, []);

  const setActiveScanner = useCallback(
    (scanner: Scanner | undefined) => {
      if (!hasScannersAccess || scanner == null) {
        deleteActiveScanner();
        return;
      }

      setCurrentScanner(scanner);
      setSearch(updateSearch({ [SCANNER_PARAM]: scanner }));
    },
    [hasScannersAccess, setSearch, deleteActiveScanner],
  );

  return {
    activeScanner,
    setActiveScanner,
    deleteActiveScannerParam,
    deleteActiveScanner,
  };
};

export default useActiveScanner;
