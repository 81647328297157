import AlignVerticalCenterIcon from '@mui/icons-material/AlignVerticalCenter';
import InsightsIcon from '@mui/icons-material/Insights';
import { Feed, Home } from '@mui/icons-material';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import {
  DataExchangeAgreement,
  DataExchangeAgreementMap,
  HomeQuadrantTabId,
  IndicesContentType,
  ProductType,
  ScannerColumnKey,
  SubLevel,
} from '../types';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import BalanceIcon from '@mui/icons-material/Balance';
import CycloneIcon from '@mui/icons-material/Cyclone';
import { Box } from '@mui/material';
import BellCurve from '../icons/BellCurve';
import TapeIcon from '../icons/TapeIcon';

export const STREAM_HOST =
  import.meta.env.VITE_STREAMING_HOST ?? 'bbg.stream.spotgamma.com';

export const STREAM_HOST_URL = `https://${STREAM_HOST}`;

export const BETA_MODE = import.meta.env.VITE_BETA_MODE === 'true';
export const DEV_MODE = import.meta.env.VITE_DEV_MODE === 'true';
export const NON_PROD = DEV_MODE || BETA_MODE;

export const MOBILE_MAX_WIDTH = 768; // showing mobile UI on tablets as well

export const HOME_LINK = {
  link: '/home',
  label: 'Market Overview',
  icon: <Home width="24px" />,
};

export const PRODUCT_LINKS: Record<
  ProductType,
  {
    link: string;
    label: string;
    icon: JSX.Element;
    isNew?: boolean;
    betaOnly?: boolean;
  }
> = {
  [ProductType.HOME]: HOME_LINK,
  [ProductType.EQUITYHUB]: {
    link: '/equityhub',
    label: 'Equity Hub',
    icon: <AlignVerticalCenterIcon width="24px" />,
  },
  [ProductType.SCANNERS]: {
    link: '/scanners',
    label: 'Scanners',
    icon: <QrCodeScannerIcon width="24px" />,
  },
  [ProductType.HIRO]: {
    link: '/hiro',
    label: 'HIRO',
    icon: <InsightsIcon width="24px" />,
  },
  [ProductType.INDICES]: {
    link: '/indices',
    label: 'Indices',
    icon: <LeaderboardIcon width="24px" />,
  },
  [ProductType.FOUNDERS_NOTES]: {
    link: '/foundersNotes',
    label: "Founder's Notes",
    icon: <Feed width="24px" />,
  },
  [ProductType.INTEGRATIONS]: {
    link: '/integrations',
    label: 'Integrations',
    icon: <ElectricalServicesIcon width="24px" />,
  },
  [ProductType.IMPLIED_VOL]: {
    link: '/ivol',
    label: 'Volatility Dashboard',
    icon: (
      <Box>
        <BellCurve width="24px" />
      </Box>
    ),
  },
  [ProductType.TAPE]: {
    link: '/tape',
    label: 'Tape',
    icon: <TapeIcon width="24px" />,
    isNew: true,
  },
  [ProductType.INTERNAL_OPEN_INTEREST]: {
    link: '/oi',
    label: 'Open Interest',
    icon: <BalanceIcon width="24px" />,
  },
  [ProductType.TRACE]: {
    link: '/trace',
    label: 'TRACE',
    icon: <CycloneIcon width="24px" />,
    isNew: true,
  },
};

export const ALL_PRODUCTS: ProductType[] = [
  ProductType.HOME,
  ProductType.HIRO,
  ProductType.IMPLIED_VOL,
  ProductType.EQUITYHUB,
  ProductType.SCANNERS,
  ProductType.FOUNDERS_NOTES,
  ProductType.INDICES,
  ProductType.INTEGRATIONS,
  ProductType.INTERNAL_OPEN_INTEREST,
  ProductType.TRACE,
  ProductType.TAPE,
];

export const DEV_ONLY_PRODUCTS: ProductType[] = [];
export const BETA_ONLY_PRODUCTS: ProductType[] = [];
export const INTERNAL_ONLY_PRODUCTS: ProductType[] = [
  ProductType.INTERNAL_OPEN_INTEREST,
];

export const RELEASED_PRODUCTS: ProductType[] = ALL_PRODUCTS.filter(
  (prod: ProductType) =>
    !DEV_ONLY_PRODUCTS.includes(prod) && !BETA_ONLY_PRODUCTS.includes(prod),
);

export const STANDARD_PRODUCTS: ProductType[] = [
  ProductType.HOME,
  ProductType.FOUNDERS_NOTES,
  ProductType.INDICES,
  ProductType.INTEGRATIONS,
];

export const ONLY_PRO_PRODUCTS: ProductType[] = [
  ProductType.EQUITYHUB,
  ProductType.SCANNERS,
];

export const PRO_PRODUCTS: ProductType[] =
  ONLY_PRO_PRODUCTS.concat(STANDARD_PRODUCTS);

export const ONLY_ALPHA_PRODUCTS: ProductType[] = [
  ProductType.HIRO,
  ProductType.IMPLIED_VOL,
  ProductType.TRACE,
  ProductType.TAPE,
];

export const ALPHA_PRODUCTS: ProductType[] =
  ONLY_ALPHA_PRODUCTS.concat(PRO_PRODUCTS);

export const SUBSCRIPTION_PRODUCTS: Record<SubLevel, ProductType[]> = {
  [SubLevel.NONE]: [],
  [SubLevel.STANDARD]: STANDARD_PRODUCTS.filter(
    (pt: ProductType) => pt !== ProductType.HOME,
  ),
  [SubLevel.PRO]: ONLY_PRO_PRODUCTS,
  [SubLevel.ALPHA]: ONLY_ALPHA_PRODUCTS,
};

// On DEV, what access to products do users have?
export const DEV_ACCESS_TO_PRODUCTS: Record<string, ProductType[]> = {
  beta: [],
  internal: ALL_PRODUCTS,
  override: ALL_PRODUCTS,
  '': [],
};

// On BETA (staging), what access to products do users have?
export const BETA_ACCESS_TO_PRODUCTS: Record<string, ProductType[]> = {
  beta: ALL_PRODUCTS,
  internal: ALL_PRODUCTS,
  override: ALL_PRODUCTS,
  '': [],
};

// On PROD, what access to products do users have?
export const PROD_ACCESS_TO_PRODUCTS: Record<string, ProductType[]> = {
  beta: RELEASED_PRODUCTS,
  internal: ALL_PRODUCTS,
  override: RELEASED_PRODUCTS,
  '': RELEASED_PRODUCTS,
};

export const ZENDESK_SETTINGS = {
  position: {
    horizontal: 'right',
    vertical: 'bottom',
  },
  offset: { horizontal: '0px', vertical: '0px' },
};

export const RIGHT_PROPS = {
  flexDirection: 'row-reverse',
  justifyContent: 'flex-end',
};
export const LEFT_PROPS = { alignItems: 'left' };

export const DEFAULT_POSITIVE_TREND_COLOR = '#18bdac';

export const DEFAULT_NEGATIVE_TREND_COLOR = '#cc304a';

export const FREE_SYMBOLS = ['TSLA', 'AAPL', 'NVDA', 'MSFT', 'AMZN'];

export const MAIN_CONTENT_DEFAULT_WIDTH = 0.7;

export const historyOnlyFields = [
  ScannerColumnKey.tradeDate,
  ScannerColumnKey.putDelta,
  ScannerColumnKey.callDelta,
];

export const scannerOnlyFields = [
  'isWatchlisted',
  ScannerColumnKey.sym,
  ScannerColumnKey.price,
  ScannerColumnKey.volume,
  ScannerColumnKey.wkHigh52,
  ScannerColumnKey.wkLow52,
  ScannerColumnKey.earningsDate,
  ScannerColumnKey.dpi5day,
];

export const scannerFields = Object.values(ScannerColumnKey).filter(
  (sck) => !historyOnlyFields.includes(sck),
);
export const historyFields = Object.values(ScannerColumnKey).filter(
  (sck) => !scannerOnlyFields.includes(sck),
);

export const scannerUnmodifiableFields = [
  'isWatchlisted',
  ScannerColumnKey.sym,
  ScannerColumnKey.tradeDate,
];

export const DATA_EXCHANGE_AGREEMENT_QUESTIONS: Record<
  DataExchangeAgreement,
  string
> = {
  [DataExchangeAgreement.INSTITUTIONAL_TRADER]:
    'Are you an institutional trader?',
  [DataExchangeAgreement.NON_BUSINESS_DATA_USAGE]:
    'Do you use stock market data exclusively for personal, non-business use?',
  [DataExchangeAgreement.REGISTERED_BODY]:
    'Are you registered or qualified with the SEC, CFTC, any securities agency, exchange, association or regulatory body?',
  [DataExchangeAgreement.ASSET_MANAGER]:
    'Are you an asset manager or investment advisor?',
  [DataExchangeAgreement.EXTERNAL_CAPITAL_USE]:
    'Do you use the capital of any other individual or entity in your trading?',
  [DataExchangeAgreement.TRADING_FOR_CORP]:
    'Do you conduct trading for the benefit of a corporation, partnership, or other entity?',
  [DataExchangeAgreement.IS_PROFIT_SHARING]:
    'Have you entered into any profit-sharing agreement related to your trading activities?',
  [DataExchangeAgreement.BENEFIT_EXCHANGE_FOR_TRADING]:
    'Are you receiving office space, equipment or other benefits in exchange for trading or financial consulting work?',
};

export const DEFAULT_AGREEMENT_FORM: DataExchangeAgreementMap = {
  [DataExchangeAgreement.INSTITUTIONAL_TRADER]: null,
  [DataExchangeAgreement.NON_BUSINESS_DATA_USAGE]: null,
  [DataExchangeAgreement.REGISTERED_BODY]: null,
  [DataExchangeAgreement.ASSET_MANAGER]: null,
  [DataExchangeAgreement.EXTERNAL_CAPITAL_USE]: null,
  [DataExchangeAgreement.TRADING_FOR_CORP]: null,
  [DataExchangeAgreement.IS_PROFIT_SHARING]: null,
  [DataExchangeAgreement.BENEFIT_EXCHANGE_FOR_TRADING]: null,
};

export const CONTENT_TYPE_LABEL_MAP: Map<string, string> = new Map<
  string,
  string
>([
  // indices content
  [IndicesContentType.REAL_TIME_LEVELS, 'Real Time Updates'],
  [IndicesContentType.GAMMA_LEVELS, 'Absolute Gamma'],
  [IndicesContentType.TILT, 'Gamma Tilt Chart'],
  [IndicesContentType.EXP_CONCENTRATION, 'Expiration Concentration'],
  [IndicesContentType.COMBO_STRIKES, 'Combo Strikes'],
  [IndicesContentType.ZERO_DTE, '0DTE Volume/Open Interest'],
  [IndicesContentType.GAMMA_MODEL, 'Gamma Model'],
  [IndicesContentType.VANNA_MODEL, 'Vanna Model'],
  [IndicesContentType.DELTA_MODEL, 'Delta Model'],
  [IndicesContentType.VOLFORECAST_MODEL, 'SIV Index'],
  [IndicesContentType.OI_VOLUME, 'Open Interest & Volume'],
  [IndicesContentType.EXP_CONCENTRATION_TABLE, 'Concentration Table'],
  [IndicesContentType.STRIKE_CONCENTRATION_TABLE, 'Strike Table'],
  [IndicesContentType.OPTIONS_RISK_REVERSAL_CHART, 'Options Risk Reversal'],
  [IndicesContentType.HISTORICAL_CHART, 'Historical Chart'],
  [IndicesContentType.MAX_REAL_VOL, 'Price vs Realized Volatility'],
  [IndicesContentType.HIST_RETURNS, '5 Day & 1 Month Return Histogram'],
  [IndicesContentType.VOLATILITY, 'Realized Volatility'],
  // home only content
  [HomeQuadrantTabId.LIVE_PRICES_CHART, 'Live Prices'],
  [HomeQuadrantTabId.SG_LEVELS, 'SpotGamma Levels'],
  [HomeQuadrantTabId.INDEX_LEVELS, 'Index Levels'],
  [HomeQuadrantTabId.AT_SPOTGAMMA, '@ SpotGamma'],
  [HomeQuadrantTabId.MACRO_ECONOMIC_CALENDAR, 'Events Calendar'],
  [HomeQuadrantTabId.EARNINGS, 'Earnings'],
  [HomeQuadrantTabId.EARNINGS_CHART, 'Earnings Chart'],
  [HomeQuadrantTabId.SCANNER_SQUEEZE_CANDIDATES, 'Squeeze Candidates'],
  [HomeQuadrantTabId.SCANNER_VRP_CANDIDATES, 'VRP'],
  [HomeQuadrantTabId.INDEX_METRICS, 'Index Metrics'],
  [HomeQuadrantTabId.SUPP_RESISTANCE_LEVELS, 'Support & Resistance'],
  [HomeQuadrantTabId.OCC_CHART, 'Options Clearning Corporation'],
  [HomeQuadrantTabId.ZERO_DTE_EQUITY_VOL_CHART, '0DTE Equity Volume/OI'],
  [HomeQuadrantTabId.EQUITY_PUT_CALL_RATIO_CHART, 'Equity Put/Call Ratio'],
  [HomeQuadrantTabId.EXP_CONCENTRATION_CHART, 'Expiration Concentration'],
]);

export const USING_NEW_MODEL = import.meta.env.VITE_SPOTGAMMA_API!.includes(
  'dev.api.spotgamma',
);

export const USER_ENDPOINTS_BLACKLIST = [
  'v1/login',
  'v1/sgMyself',
  'v1/myAlerts', // not sure about this one
  'v1/myAlertsByDays', // not sure about this one
  'v1/me/pollUpdate',
  'v1/me/tnsFilters',
  'v1/me/markNotificationsSeen',
  'v1/me/tnsFilters',
  'v1/me/submitReview',
  'v1/me/review',
  'v1/me/watchlists',
  'v1/me/settings',
  'v1/me/saveWatchlists',
  'v1/users/saveAlertsSettings',
  'v1/me/saveIsInstitutional',
  'v1/me/institutionalForm',
];

export const SEEN_OH_UPSELL_KEY = 'sg_oh'; // equivalent to spotgamma_openHouse

export const OPEN_HOUSE_UPSELL = {
  title: "WELCOME TO SPOTGAMMA'S OPEN HOUSE",
  subtitle: 'FREE ACCESS AVAILABLE TO YOU FOR A LIMITED TIME',
  body: "This week only, enjoy free access to SpotGamma tools and data. We are confident that once you use our tools, you won't go back to trading in the dark. Sign up by Tuesday, December 17, and receive 40% OFF any annual subscription.",
};
