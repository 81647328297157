import dayjs, { Dayjs } from 'dayjs';
import { BlackScholes, findIVol } from './BlackScholes';
import { fetchRawAPI } from './shared/fetch';
import { decode } from '@msgpack/msgpack';
import {
  ET,
  formatAsPercentage,
  getCurrentDate,
  predicateSearch,
  findNearestIdx,
  roundToStartOfDay,
  DAY_IN_MS,
  formatAsDelta,
  spansNonMktDays,
} from './shared';
import * as d3 from 'd3';
import {
  OPTION_IDX,
  RawGreeksDataMap,
  GreeksData,
  GREEK_IDX,
  RawGreeksObject,
  RawStatsData,
  TermStructure,
  RawStatsObject,
  STAT_IDX,
  ExpirationsDisplayType,
  StrikeDisplayType,
  VolSkew,
  MatrixColumnData,
  MatrixData,
  DailyGreeks,
  Point,
} from '../types';

export const DEFAULT_STATS_LOOKBACK = '60';
const { CALL, PUT } = OPTION_IDX;

export const ROW_WIDTH = 100;
export const ROW_HEIGHT = 40;
export const PAGE_OFFSET_BOTTOM = 10;
export const VSKEW_DEFAULT_DTE = 30;

export const IVOL_STATS_KEY = 'stats';
export const SKEW_PREM_KEY = 'skew-prem';
export const DELTA_NORMED_KEY = 'delta-normed';
export const DELTA_KEY = 'delta';
export const THETA_KEY = 'theta';
export const THETA_PERCENT_KEY = 'theta-percent';

const SKEW_PRICE_THRESHOLD_MULTIPLIER = 0.000009;

export const getDateFromUtc = (utcTime: string): Dayjs =>
  dayjs(Number(utcTime));

export const MIN_QUERY_DATE = dayjs('2023-09-18').tz(ET);

export const getGreeksFromMsgPack = async (rawMsgPackResponse: any) => {
  if (rawMsgPackResponse.status >= 300) {
    throw rawMsgPackResponse.error;
  }
  const arrayBuffer = await rawMsgPackResponse.arrayBuffer();
  const expirationMap: any = decode(arrayBuffer);
  return expirationMap as RawGreeksDataMap;
};

// Utility function to fetch data from the API
export const fetchRawFormattedData = async (
  endpoint: string,
): Promise<RawGreeksDataMap> => {
  const response = await fetchRawAPI(endpoint);
  return getGreeksFromMsgPack(response);
};

// Weight our implied volatility to the more out-of-the-money instrument.
// i.e.
//   At put delta -0.05, we take 0.95 * putIV + 0.05 * callIV
//        At delta 0.50, we take the average: (putIV + callIV) / 2
//   At call delta 0.05, we take 0.95 * callIV + 0.05 * putIV
export const getIVFromGreeks = (greeks: GreeksData) => {
  const callDelta = greeks[CALL][GREEK_IDX.DELTA];
  const idx = callDelta <= 0.5 ? CALL : PUT;
  const delta = Math.abs(greeks[idx][GREEK_IDX.DELTA]);
  return (
    greeks[idx][GREEK_IDX.VOLATILITY] * (1 - delta) +
    greeks[1 - idx][GREEK_IDX.VOLATILITY] * delta
  );
};

// utcTime1 is initial starting date
// utcTime2 is end date
export const convertToDaysTillExpiry = (
  utcTime1: number,
  utcTime2: number,
): number => {
  // rounding helps with ignoring the time of day so the DTE calculation is purely done on the basis of days in between
  // e.g. Getting DTE from 11/16 at 6pm to 11/17 8am should yield 1 DTE not 0 DTE
  const initialDate = dayjs(roundToStartOfDay(utcTime1));
  const endDate = dayjs(roundToStartOfDay(utcTime2));
  return endDate.diff(initialDate, 'day');
};

const ATM_DELTA = 0.5;

// Find the call option greek values at delta 50.  For Near-term expirations,
// this is essentially at "current price".  for long-term expirations, delta 50
// is at the forward price
export const findD50Greeks = (
  strikeMap: RawGreeksObject,
  idx: number,
): number => {
  const strikes: number[] = Object.keys(strikeMap)
    .map(Number)
    .sort((a, b) => a - b);

  // extract Call Deltas, delta idx = 1
  const deltas: number[] = strikes.map(
    (s) => strikeMap[s][CALL][GREEK_IDX.DELTA],
  );

  // extract Greek values
  const values: number[] = strikes.map((s) => strikeMap[s][CALL][idx]);

  const idxA = predicateSearch(deltas, (n) => n > ATM_DELTA);
  const idxB = Math.min(idxA + 1, deltas.length - 1);
  const fraction = (ATM_DELTA - deltas[idxB]) / (deltas[idxA] - deltas[idxB]);
  return d3.interpolateNumber(values[idxB], values[idxA])(fraction);
};

// Find the call option implied volatility at precisely delta 50
export const findD50IV = (strikeDataMap: RawGreeksObject): number => {
  return findD50Greeks(strikeDataMap, GREEK_IDX.VOLATILITY);
};

export const findD50Price = (strikeMap: RawGreeksObject): number => {
  const strikes: number[] = Object.keys(strikeMap)
    .map(Number)
    .sort((a, b) => a - b);
  const deltas: number[] = strikes.map(
    (s) => strikeMap[s][CALL][GREEK_IDX.DELTA],
  );
  const idxA = predicateSearch(deltas, (n) => n > ATM_DELTA);
  const idxB = Math.min(idxA + 1, deltas.length - 1);
  const fraction = (ATM_DELTA - deltas[idxB]) / (deltas[idxA] - deltas[idxB]);
  return d3.interpolateNumber(strikes[idxB], strikes[idxA])(fraction);
};

export const findParityPrice = (strikeMap: RawGreeksObject): number => {
  const strikes: number[] = Object.keys(strikeMap)
    .map(Number)
    .sort((a, b) => a - b);
  const priceDeltas: number[] = strikes.map(
    (s) =>
      strikeMap[s][CALL][GREEK_IDX.PRICE] - strikeMap[s][PUT][GREEK_IDX.PRICE],
  );
  const idxA = predicateSearch(priceDeltas, (p) => p >= 0);
  const idxB = Math.min(idxA + 1, priceDeltas.length - 1);
  const fraction =
    (0 - priceDeltas[idxB]) / (priceDeltas[idxA] - priceDeltas[idxB]);
  return d3.interpolateNumber(strikes[idxB], strikes[idxA])(fraction);
};

export const interpolateGreeksAtStrike = (
  strikeDataMap: RawGreeksObject,
  tgtStrike: number,
): GreeksData => {
  const strikes: number[] = Object.keys(strikeDataMap)
    .map(Number)
    .sort((a, b) => a - b);
  const idxA = predicateSearch(strikes, (s) => s <= tgtStrike);
  const idxB = Math.min(idxA + 1, strikes.length - 1);
  const interpolate = d3.interpolate(
    strikeDataMap[strikes[idxA]],
    strikeDataMap[strikes[idxB]],
  );
  const fraction =
    (tgtStrike - strikes[idxA]) / (strikes[idxB] - strikes[idxA]);
  return interpolate(fraction);
};

const getForwardIV = (
  fromDate: Dayjs,
  currExp: number,
  prevExp: number,
  currIV: number,
  prevIV: number,
) => {
  const currDTE = (currExp - fromDate.valueOf()) / DAY_IN_MS;
  const prevDTE = (prevExp - fromDate.valueOf()) / DAY_IN_MS;

  return Math.sqrt(
    (currDTE * Math.pow(currIV, 2) - prevDTE * Math.pow(prevIV, 2)) /
      (currDTE - prevDTE),
  );
};

// From raw greeks data, create an array of TermStructure objects,
// filtered to only include up to 365 days till expiry
export const extractTermStructureData = (
  expirationMap: RawGreeksDataMap,
  fromDate: Dayjs,
  timeFrame: string,
  statsDataMap: RawStatsData,
): TermStructure[] => {
  const dataEntries: [string, RawGreeksObject][] =
    Object.entries(expirationMap);

  const statsMapEntries: [string, RawStatsObject][] =
    Object.entries(statsDataMap);

  const tteMs = statsMapEntries.map(([tte]: [string, RawStatsObject]) =>
    Number(tte),
  );

  const result: TermStructure[] = dataEntries.map(
    ([utcTime, strikeDataMap]: [string, RawGreeksObject], index: number) => {
      // strikeData is a map of key/value pairs where:
      // key is the strike for call/put
      // value is greeks data formatted as [call[], put[]] where each call/put array has 7 numbers
      const atmIV = findD50IV(strikeDataMap);

      // Convert date to days till expiry from the first date (which is either current day or user selected)
      const exp = Number(utcTime);
      const tte = exp - fromDate.valueOf();
      const daysTillExpiration: number = convertToDaysTillExpiry(
        fromDate.valueOf(),
        exp,
      );

      const termStructure: TermStructure = {
        daysToExpiry: daysTillExpiration,
        expirationDate: exp,
        [timeFrame]: atmIV,
      };

      if (index > 0) {
        let prevIdx = index - 1;
        let [prevExp, prevStrikeDataMap] = dataEntries[prevIdx];
        const cur = dayjs(exp);

        // If the previous expiration is under a week in length and we span a
        // weekend, find the closest previous expiration that is a week away from our current expiration
        let prev = dayjs(Number(prevExp));
        while (
          cur.diff(prev, 'days') < 7 &&
          spansNonMktDays(cur, prev) &&
          prevIdx > 0
        ) {
          [prevExp, prevStrikeDataMap] = dataEntries[--prevIdx];
          prev = dayjs(Number(prevExp));
        }

        termStructure[`${timeFrame}/FIV`] = getForwardIV(
          fromDate,
          exp,
          prev.valueOf(),
          atmIV,
          findD50IV(prevStrikeDataMap),
        );
      }

      // Find corresponding greeks object DTE closest to the stats entry dte
      const closestIdx = findNearestIdx(tteMs, tte);
      let statsEntry: [string, RawStatsObject] = statsMapEntries[closestIdx];
      if (statsEntry == null) {
        // No stats to handle
        return termStructure;
      }

      // if delta 0.5 exists use it, otherwise find the next closest delta to 0.5
      const statsObj: RawStatsObject = statsEntry[1];
      let entry: number[] = statsObj[0.5];
      if (entry == null) {
        const normedStats: [number, number[]][] = Object.keys(statsObj)
          .map<[number, number[]]>((delta: string) => {
            const d = Number(delta);
            return [d < 0 ? 1 + d : d, statsObj[d]];
          })
          .sort(([d1]: [number, any], [d2]: [number, any]) => d1 - d2);

        const idx = findNearestIdx(
          normedStats.map((ns) => ns[0]),
          0.5,
        );
        entry = normedStats[idx][1];
      }

      // get lower and upper bounds, default to 10% & 90% percentiles
      // TODO: use state params to get the user configured stats
      const statsLowerBound = entry[STAT_IDX.P10];
      const statsUpperBound = entry[STAT_IDX.P90];
      termStructure[`bounds-${timeFrame}`] = [statsLowerBound, statsUpperBound];

      return termStructure;
    },
  );

  return result.filter(
    (termStructure: TermStructure) => termStructure.daysToExpiry <= 365,
  );
};

// Dynamically merges 2 arrays of same object type with potentially different lengths.
// Using the value at the sharedKey attribute as the "entry" key, i.e. whether its a new entry or an existing one.
// Why make it generic? Because sometimes new objects need to be added while other times existing objects are to be updated
// For example: chart with 3 lines on it, each with x/y values, a 4th line is dynamically added but contains more x/y values,
// 1. Chart data is represented by JS Objects, we add "new" x/y values as new objects wit new key/value pairs, thus it needs it's own object key
// 2. Sometimes Y values for a certain line on the chart need to be simply updated
export const mergeObjectLists = <T extends Object>(
  prevData: T[],
  newData: T[],
  sharedKey: keyof T,
): T[] => {
  if (prevData.length === 0) {
    return newData;
  }

  const mergedDataMap = new Map<any, T>();

  prevData.forEach((item) => mergedDataMap.set(item[sharedKey], { ...item }));

  newData.forEach((item) => {
    const existingItem = mergedDataMap.get(item[sharedKey]);
    mergedDataMap.set(item[sharedKey], { ...(existingItem || {}), ...item });
  });

  return Array.from(mergedDataMap.values());
};

const CURRENT_YEAR = dayjs().year();
export const getExpirationLabel = (expirationDateUtc: string) => {
  const date: Dayjs = dayjs.utc(Number(expirationDateUtc));
  const amLabel = `${date.hour() < 12 ? ' AM' : ''}`;

  if (date.year() === CURRENT_YEAR) {
    return `${date.format('MM-DD')} ${amLabel}`;
  }
  return `${date.format('YYYY-MM-DD')} ${amLabel}`;
};

// This function generates unique "field" key that can be used on line charts or anywhere
// to represent an exact selection of (TradeDate | ExpirationDate) pair
export const getVolSkewKey = (tradeDate: Dayjs, expirationDateUtc: string) =>
  `${tradeDate.format('YYYY-MM-DD')}-${expirationDateUtc}`;

export const getVolSkewLineLabel = (
  tradeDate: Dayjs,
  expirationDateUtc: string,
  timezone: string,
) => {
  const label = getTradeDateDisplayText(tradeDate, timezone);

  const dte = getExpirationDisplayText(tradeDate, expirationDateUtc);

  return `${label} | ${dte}`;
};

export const getTradeDateDisplayText = (tradeDate: Dayjs, timezone: string) => {
  const label = tradeDate.isSame(getCurrentDate(), 'day')
    ? `Today ${getCurrentDate(timezone).format('LT')}`
    : tradeDate.format('YYYY-MM-DD');

  return label;
};

export const getExpirationDisplayText = (
  tradeDate: Dayjs,
  expirationDateUtc: string,
) => {
  const label = `${getExpirationLabel(
    expirationDateUtc,
  )}, ${convertToDaysTillExpiry(
    tradeDate.valueOf(),
    Number(expirationDateUtc),
  )} DTE`;

  return label;
};

export const getTermStructureKeyFromLabel = (
  expLabelType: ExpirationsDisplayType,
) => {
  if (expLabelType === ExpirationsDisplayType.DaysToExpiration) {
    return 'daysToExpiry';
  } else {
    return 'expirationDate';
  }
};

export const getVolSkewKeyFromLabel = (strLabelType: StrikeDisplayType) => {
  if (strLabelType === StrikeDisplayType.FixedStrike) {
    return 'strike';
  } else if (strLabelType === StrikeDisplayType.Moneyness) {
    return 'moneyness';
  } else {
    return 'delta-x';
  }
};

const MIN_SLOPE = 0.001;

export const getSnapshotTime = (greeksMap: RawGreeksDataMap): number => {
  const dataEntries: [string, RawGreeksObject][] = Object.entries(greeksMap);
  return Math.max(
    ...dataEntries.map(([, rawObj]: [string, RawGreeksObject]) => {
      return Math.max(
        ...Object.values(rawObj).map((greeks: GreeksData) =>
          Math.max(
            greeks[CALL][GREEK_IDX.TIMESTAMP],
            greeks[PUT][GREEK_IDX.TIMESTAMP],
          ),
        ),
      );
    }),
  );
};

export const getStatsFromExp = (
  statsDataObj: RawStatsData,
  greeksMap: RawGreeksDataMap,
  utcExp: string,
): RawStatsObject => {
  const tteMs = Object.keys(statsDataObj)
    .map(Number)
    .sort((a, b) => b - a);

  const snapTime = getSnapshotTime(greeksMap);

  const exp = Number(utcExp);
  let idx = predicateSearch(tteMs, (t: number) => exp <= snapTime + t);
  if (
    idx + 1 < tteMs.length &&
    Math.abs(snapTime + tteMs[idx + 1] - exp) <
      Math.abs(snapTime + tteMs[idx] - exp)
  ) {
    ++idx;
  }
  return statsDataObj[tteMs[idx]];
};

// this function returns a map of expirationDate (greeks) -> statsObj (RawStatsObject with delta -> stats entries)
export const getExpStatsMap = (
  statsDataObj: RawStatsData,
  greeksMap: RawGreeksDataMap,
): Map<number, RawStatsObject> => {
  return new Map(
    Object.keys(greeksMap).map((utcExp: string) => {
      const exp = Number(utcExp);
      const statsObj = getStatsFromExp(statsDataObj, greeksMap, utcExp);

      return [exp, statsObj];
    }),
  );
};

// Convert put delta to call delta
export const normDelta = (delta: number) => (delta < 0 ? 1 + delta : delta);

const getNormedDeltas = (
  deltaStatsMap: RawStatsObject,
): [number, number[]][] => {
  return Object.entries(deltaStatsMap)
    .map<[number, number[]]>(([key, stats]: [string, number[]]) => {
      const n = Number(key);
      return [normDelta(n), stats]; // convert put delta to call delta
    })
    .sort(([ak, _av], [bk, _bv]) => bk - ak);
};

// x,y coordinate inputs for quintile bezier curve defining the normed-delta's
// relationship to the x-axis.  start: 0,0. end: 1,1 (ala CSS bezier
// animations), for a total of 6 points.
const A = { x: 0.0, y: 0.55 };
const B = { x: 0.15, y: 0.2 };
const C = { x: 0.9, y: 0.5 };
const D = { x: 1.0, y: 0.3 };

// Tweaked quintic bezier function with y inverted to accomodate normed-delta moving from 1 to 0
const deltaBezier = (t: number): Point => {
  const x =
    5 * t * (1 - t) ** 4 * A.x +
    10 * t ** 2 * (1 - t) ** 3 * B.x +
    10 * t ** 3 * (1 - t) ** 2 * C.x +
    5 * t ** 4 * (1 - t) * D.x +
    t ** 5;
  const y =
    5 * t * (1 - t) ** 4 * A.y +
    10 * t ** 2 * (1 - t) ** 3 * B.y +
    10 * t ** 3 * (1 - t) ** 2 * C.y +
    5 * t ** 4 * (1 - t) * D.y +
    t ** 5;
  return { x, y: 1 - y };
};

// x'(t). Used in Newton-Raphson-based finder logic in findBezierPoint.
const bezierDX = (t: number): number =>
  (5 - 25 * t) * (1 - t) ** 3 * A.x +
  -10 * t * (5 * t - 2) * (1 - t) ** 2 * B.x +
  10 * t ** 2 * (5 * t ** 2 - 8 * t + 3) * C.x +
  5 * (4 - 5 * t) * t ** 3 * D.x +
  5 * t ** 4;

// y'(t).  Used in Newton-Raphson-based finder logic in findBezierPoint.
const bezierDY = (t: number): number =>
  -(
    (5 - 25 * t) * (1 - t) ** 3 * A.y +
    -10 * t * (5 * t - 2) * (1 - t) ** 2 * B.y +
    10 * t ** 2 * (5 * t ** 2 - 8 * t + 3) * C.y +
    5 * (4 - 5 * t) * t ** 3 * D.y +
    5 * t ** 4
  );

const EPSILON = 1e-9;

// Take a delta target (x) or an x-axis target (y) and return the mapped point.
const findBezierPoint = (tgt: { x?: number; y?: number }): Point => {
  const key = tgt.x != null ? 'x' : 'y';
  const derivative = tgt.x != null ? bezierDX : bezierDY;

  // use x or (1 - y) as initial best guesses of t (i.e. x = y line)
  let t: number = tgt.x ?? 1 - tgt.y!;
  let pt = deltaBezier(t);
  let iter = 0;
  while (Math.abs(pt[key] - tgt[key]!) > EPSILON) {
    let deriv = derivative(t);
    t += (tgt[key]! - pt[key]) / deriv;
    pt = deltaBezier(t!);
    if (++iter > 20) {
      // this should be impossible with a monotically increasing Bezier curve
      // (as we've chosen), but make sure we never hit an infinite loop because
      // Newton-Raphson didn't converge
      console.error('Delta Bezier did not converge');
      break;
    }
  }
  return pt;
};

// Map ("normed") delta to the x-axis in such a way that we shrink x-axis real-estate
// near-ATM delta and elongate x-axis space in more OTM deltas
export const getDeltaX = (delta: number) => {
  return findBezierPoint({ x: delta }).y;
};

export const getDeltaFromX = (x: number) => {
  return findBezierPoint({ y: x }).x;
};

export const extractVolSkewData = (
  strikesMap: RawGreeksObject,
  timeFrame: string,
  deltaStatsMap: RawStatsObject | null,
): VolSkew[] => {
  const strikeMapEntries: [string, GreeksData][] = Object.entries(strikesMap);

  let fwdPrice = findD50Price(strikesMap);
  if (isNaN(fwdPrice)) {
    fwdPrice = findParityPrice(strikesMap);
  }
  const priceThreshold = SKEW_PRICE_THRESHOLD_MULTIPLIER * fwdPrice;

  const result: VolSkew[] = strikeMapEntries
    .filter(([_strike, callPutArray]: [string, GreeksData], idx, entries) => {
      const callDelta = callPutArray[CALL][GREEK_IDX.DELTA];
      if (callDelta >= 0.05 && callDelta <= 0.95) {
        return true;
      }
      const optionIDX = callDelta > 0.5 ? PUT : CALL;
      const inst = callPutArray[optionIDX];
      if (idx < entries.length - 1 || idx > 0) {
        const delta = inst[GREEK_IDX.DELTA];
        if (Math.abs(delta) < 0.001) {
          return false;
        }

        const neighborIDX = idx === entries.length - 1 ? idx - 1 : idx + 1;
        const [_ns, nextEntry] = entries[neighborIDX];
        const nextInst = nextEntry[optionIDX];
        const nextVol = nextInst[GREEK_IDX.VOLATILITY];
        const run = nextInst[GREEK_IDX.DELTA] - delta;
        const rise = nextVol - callPutArray[optionIDX][GREEK_IDX.VOLATILITY];
        const slope = rise / run;
        return Math.abs(slope) > MIN_SLOPE;
      }
      // If there is no neighbor against which to form a slope, use priceThreshold
      return callPutArray[optionIDX][GREEK_IDX.PRICE] >= priceThreshold;
    })
    .map(([strike, callPutArray]: [string, GreeksData]) => {
      const callDelta = callPutArray[CALL][GREEK_IDX.DELTA];
      const putDelta = callPutArray[PUT][GREEK_IDX.DELTA];
      const delta = callDelta <= 0.5 ? callDelta : 1 + putDelta;
      const iv = getIVFromGreeks(callPutArray);

      const strikeNumber = Number(strike);
      const moneyness = Number((strikeNumber / fwdPrice).toFixed(3));

      const skew: VolSkew = {
        strike: strikeNumber,
        moneyness,
        [`${timeFrame}-${DELTA_KEY}`]: delta,
        'delta-x': getDeltaX(delta),
        [timeFrame]: iv,
      };

      if (deltaStatsMap == null || delta < 0.001 || delta > 0.999) {
        return skew;
      }

      const normedEntries = getNormedDeltas(deltaStatsMap);
      const normedDeltas = normedEntries.map(([d, _stats]) => d);

      // TODO: Investigate the results of the following logic. (This would
      // extrapolate IV values using slope of the first entries past their bounds)
      // statsIdx = Math.max(0, predicateSearch(normedDeltas, (d) => d >= delta));
      const statsIdx = predicateSearch(normedDeltas, (d) => d >= delta);

      if (statsIdx >= 0) {
        let statsLowerBound = normedEntries[statsIdx][1][STAT_IDX.P10];
        let statsUpperBound = normedEntries[statsIdx][1][STAT_IDX.P90];
        skew[`bounds-${timeFrame}`] = [statsLowerBound, statsUpperBound];
        if (statsIdx + 1 < normedDeltas.length) {
          const nextLowerBound = normedEntries[statsIdx + 1][1][STAT_IDX.P10];
          const nextUpperBound = normedEntries[statsIdx + 1][1][STAT_IDX.P90];
          const nextDelta = normedDeltas[statsIdx + 1];
          const fraction =
            (delta - nextDelta) / (normedDeltas[statsIdx] - nextDelta);
          const interpolate = d3.interpolate(
            [nextLowerBound, nextUpperBound],
            skew[`bounds-${timeFrame}`],
          );
          skew[`bounds-${timeFrame}`] = interpolate(fraction);
        }
      }
      return skew;
    });
  return result;
};

export const isStrikeWithinRange = (
  currentPrice: number,
  strike: number,
  range: number,
) => {
  return (
    currentPrice == null ||
    (strike >= currentPrice * (1 - range) &&
      strike <= currentPrice * (1 + range))
  );
};

export const MATRIX_SETTINGS_ZOOMED_NORMAL = {
  expColumnWidth: ROW_WIDTH,
  cellColumnWidth: ROW_WIDTH,
  height: ROW_HEIGHT,
  fontSize: '12px',
  transform: 'none',
};

export const MATRIX_SETTINGS_ZOOMED_OUT = {
  expColumnWidth: ROW_WIDTH,
  cellColumnWidth: ROW_WIDTH * 0.15,
  height: ROW_HEIGHT * 0.5,
  fontSize: '11px',
  transform: 'rotate(-45deg)',
};

// More than 3 keys means there is at least one IV value remaining along with the date
// after deletion of the key
export const isNonEmptyChartDataObject = (obj: VolSkew | TermStructure) =>
  Object.keys(obj).length > 3;

export const getFilteredGreeksData = (
  rawData: RawGreeksDataMap,
  date?: string,
): RawGreeksDataMap => {
  const currentDate = getCurrentDate();
  const snapshotTime = getSnapshotTime(rawData);
  let filteredData: Record<string, [number, RawGreeksObject]> = {};
  // Ensure we process expiration dates in order and overwrite any AM expirations with the PM expiration on the same day.
  for (const expiry of Object.keys(rawData)
    .map(Number)
    .sort((a, b) => a - b)) {
    const expirationDate: Dayjs = dayjs(expiry);
    if (
      !expirationDate.isBefore(date ?? currentDate, 'day') &&
      expiry > snapshotTime
    ) {
      filteredData[expirationDate.format('YYYY-MM-DD')] = [
        expiry,
        rawData[expiry],
      ];
    }
  }
  return Object.fromEntries(Object.values(filteredData));
};

export const buildMatrixTableData = (
  fromDate: Dayjs,
  expStrikeMap: RawGreeksDataMap,
  matrixCompareMode: boolean,
  matrixPrevExpiryMap: RawGreeksDataMap | null,
  expStatsMap: Map<number, RawStatsObject> | null,
) => {
  const colMap: Map<string, MatrixColumnData> = new Map();
  const snapTime = getSnapshotTime(expStrikeMap);
  const expirations: number[] = Object.keys(expStrikeMap).map(Number);
  let expIdx: number =
    predicateSearch(expirations, (t: number) => t < snapTime) + 1;
  expIdx = Math.min(expIdx, expirations.length - 1);
  const currentPrice = findD50Price(expStrikeMap[expirations[expIdx]]);

  const transformedData: MatrixData[] = [...Object.entries(expStrikeMap)]
    .map(([expiry, strikeMap]: [string, RawGreeksObject]) => {
      // "Forward price" is the correct reference price to use for comparing iVol for a given expiry
      const fwdPrice = findD50Price(strikeMap);
      // Call and Put greeks interpolated for fwd price as the strike. Used for calculating skew price
      const atmGreeks = interpolateGreeksAtStrike(strikeMap, fwdPrice);

      // year till expiration, 3rd parameter set to true allows dayjs to return a float value
      const yte = dayjs(Number(expiry)).diff(fromDate, 'year', true);

      // Backout implied vol from market prices to plug back into BlackScholes for Skew Premium
      const bsmArgs = { currentPrice, yte, strike: fwdPrice };
      const atmCallIV = findIVol({
        ...bsmArgs,
        isCall: true,
        price: atmGreeks[CALL][GREEK_IDX.PRICE],
      });
      const atmPutIV = findIVol({
        ...bsmArgs,
        isCall: false,
        price: atmGreeks[PUT][GREEK_IDX.PRICE],
      });

      const rowData: MatrixData = {
        expiryDate: Number(expiry),
        id: expiry,
      };

      const deltaStatsMap: RawStatsObject | undefined = expStatsMap?.get(
        Number(expiry),
      );

      // loop through each strike
      Object.entries(strikeMap).forEach(
        ([strikePrice, greeks]: [string, GreeksData]) => {
          const volatility: number = getIVFromGreeks(greeks);
          const callDelta = greeks[CALL][GREEK_IDX.DELTA];
          const putDelta = greeks[PUT][GREEK_IDX.DELTA];
          const idx = callDelta <= 0.5 ? CALL : PUT;
          const otmTheta = greeks[idx][GREEK_IDX.THETA];

          rowData[strikePrice] = volatility;
          rowData[`${strikePrice}-${DELTA_KEY}`] = greeks[idx][GREEK_IDX.DELTA];
          rowData[`${strikePrice}-${THETA_KEY}`] = otmTheta;
          rowData[`${strikePrice}-${THETA_PERCENT_KEY}`] =
            otmTheta / greeks[idx][GREEK_IDX.PRICE];

          const tgtDelta = callDelta > 0.5 ? 1 + putDelta : callDelta;

          // Figure out stats entry
          if (deltaStatsMap != null && tgtDelta >= 0.001 && tgtDelta <= 0.999) {
            let stats: number[] | null = null;
            const normedEntries = getNormedDeltas(deltaStatsMap);
            const normedDeltas = normedEntries.map(([d, _stats]) => d);
            const statsIdx = predicateSearch(
              normedDeltas,
              (d) => d >= tgtDelta,
            );

            // It's possible we can't find our target delta within stats on the wings
            if (statsIdx >= 0) {
              stats = normedEntries[statsIdx][1];
              if (statsIdx + 1 < normedDeltas.length && stats) {
                const nextDelta = normedDeltas[statsIdx + 1];
                const fraction =
                  (tgtDelta - nextDelta) / (normedDeltas[statsIdx] - nextDelta);
                const interpolate = d3.interpolate(
                  normedEntries[statsIdx + 1][1],
                  stats,
                );
                stats = interpolate(fraction);
              }
            }
            rowData[`${strikePrice}-${IVOL_STATS_KEY}`] = stats;
          }

          // Calculate Skew Premium.
          //   1. Get price of option using ATM ivol.
          //   2. Subtract that from mkt price.
          const strike = Number(strikePrice);
          const isCall = idx === CALL;
          const iVol = isCall ? atmCallIV : atmPutIV;
          const bsm = new BlackScholes({ ...bsmArgs, isCall, iVol, strike });
          rowData[`${strikePrice}-${SKEW_PREM_KEY}`] =
            greeks[idx][GREEK_IDX.PRICE] - bsm.price();
          rowData[`${strikePrice}-${DELTA_NORMED_KEY}`] = tgtDelta;

          if (!colMap.has(strikePrice)) {
            // Add the strike price as a column if it doesn't already exist
            colMap.set(strikePrice, {
              dataKey: strikePrice,
              label: strikePrice,
              numeric: true,
            });
          }
        },
      );
      return rowData;
    })
    .filter(
      // filter dates that don't exist in prev map when comparing
      (row) =>
        !matrixCompareMode || matrixPrevExpiryMap?.[row.expiryDate] != null,
    );
  // sort columns by strike, aka datakey
  const finalColumns = [...colMap.values()].sort(
    (a: MatrixColumnData, b: MatrixColumnData) =>
      Number(a.dataKey) - Number(b.dataKey),
  );
  // add first column to be expiry date
  finalColumns.unshift({
    dataKey: 'expiryDate',
    label: 'Expiry | Strike',
  });

  return {
    matrixData: transformedData,
    matrixColumnData: finalColumns,
  };
};

export const getNextAvailableColor = (
  usedColors: string[],
  availableColors: string[],
): string | undefined =>
  availableColors.find((color: string) => !usedColors.includes(color));

export const getTimeAdjustedIvValue = (
  value: number,
  fromDte: number,
  toDte: number,
) => value * Math.sqrt(fromDte / toDte);

export const closestExpirationIdx = (
  termStructureData: TermStructure[],
  termStructureSelectedGreeks: DailyGreeks[],
  daysFromNow = 90,
) => {
  const maxSnapshotTime = Math.max(
    ...termStructureSelectedGreeks.map((tsGreeks: DailyGreeks) =>
      getSnapshotTime(tsGreeks.data),
    ),
  );

  const targetDateUtc: number = dayjs(maxSnapshotTime)
    .add(daysFromNow, 'day')
    .valueOf();

  const closestIdx = predicateSearch(
    termStructureData,
    (ts) => ts.expirationDate < targetDateUtc,
  );

  return closestIdx < 0 ? termStructureData.length - 1 : closestIdx;
};

export const getGroupedPayloads = (
  differentiatorKey: string,
  data: any[],
): Record<string, any[]> =>
  data.reduce((agg, item) => {
    const baseKey = item.dataKey.replace(differentiatorKey, '');
    agg[baseKey] = agg[baseKey] ?? [];
    agg[baseKey].push(item);
    return agg;
  }, {});

export const findClosestExpiration = (
  snapTime: number,
  expirations: string[],
  targetDte: number,
) => {
  const dtes = expirations.map((e) => Number(e) - snapTime);
  const target = targetDte * DAY_IN_MS;
  const idx = findNearestIdx(dtes, target);
  return expirations[Math.max(0, idx)];
};

export const SKEW_TICK_FORMATTERS = {
  delta: formatAsDelta,
  'delta-x': (x: number) => formatAsDelta(getDeltaFromX(x)),
  moneyness: formatAsPercentage,
  strike: (v: number) => `$${v.toLocaleString('en-US')}`,
};

export const SKEW_VIEW_TOOLTIPS = {
  [StrikeDisplayType.Moneyness]:
    'The option strike price divided by the stock price; when greater than 100%, this represents a strike that is higher than the current price. When less than 100%, this is a strike that is lower than the current price.',
  [StrikeDisplayType.Delta]:
    "Delta measures the sensitivity of an option's price to movement in the underlying stock; the delta value determines how much is made or lost as the underlying stock's price moves.",
  [StrikeDisplayType.FixedStrike]:
    'The option strike price for an underlying ETF, stock, or index.',
};

export const MATRIX_DATA_SETTING_TOOLTIPS = {
  zScore:
    'The difference in standard deviations between the Implied Volatility of one cell and the statistical mean with the same corresponding expiry time and delta.',
  statsMode:
    'Displays a color gradient indicating how high (green) or low (red) implied volatility is compared to the statistical mean',
  statsLookback:
    'In Statistical Mode, you can specify a look back period of 30, 60, or 90 days to use for the calculated statistics.',
  skewPrem:
    'Displays the premium due to volatility skew for the strike and expiration.',
  compareMode:
    'Compare Implied Volatility between two historical snapshots; A red cell indicates that Implied Volatility of the reference date is lower than that of the comparison date. Green indicates Implied Volatility is higher for the reference date.',
  absoluteMode:
    'The SpotGamma teal color gradient shows how high or low Implied Volatility is for each cell relative to other strikes and expirations.',
  highlightsMode:
    'Identifies potentially mispriced options based on the Implied Volatility levels of adjacent strikes and expirations.',
};
