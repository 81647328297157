import { ReactNode, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  currentProductTypeState,
  isMobileState,
  pageBannerOpenMapState,
  userDetailsState,
} from '../../states';
import { PageBanner } from './PageBanner';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Box,
  Link as MUILink,
  Stack,
  SxProps,
  Typography,
} from '@mui/material';
import {
  showNoLoginUI,
  isValidTokenOverrideTimeframe,
  getAppcues,
  tutorialFlowIdForLocation,
} from '../../util';
import { blue } from '@mui/material/colors';
import { PRODUCT_LINKS } from '../../config';
import useAuth from '../../hooks/auth/useAuth';
import { ProductType } from '../../types';

export type PageProps = {
  children: ReactNode | ReactNode[];
  showLoggedOutBanner?: boolean;
  sx?: SxProps;
};

export const Page = ({
  children,
  showLoggedOutBanner = true,
  sx = {},
}: PageProps) => {
  const userDetails = useRecoilValue(userDetailsState);
  const navigate = useNavigate();
  const isMobile = useRecoilValue(isMobileState);
  const [pageBannerOpenMap, setPageBannerOpenMap] = useRecoilState(
    pageBannerOpenMapState,
  );
  const currentProductType = useRecoilValue(currentProductTypeState);
  const [searchParams] = useSearchParams();
  const appcues = getAppcues();
  const { productsWithAccess } = useAuth();

  const tutorialId = tutorialFlowIdForLocation(
    currentProductType,
    searchParams,
  );

  const shouldBannerOpenOnInit =
    (userDetails == null ||
      (currentProductType &&
        !productsWithAccess.has(currentProductType) &&
        isValidTokenOverrideTimeframe())) &&
    showLoggedOutBanner &&
    !showNoLoginUI();

  const isOpen =
    currentProductType != null &&
    pageBannerOpenMap?.get(currentProductType) === true;

  const setIsOpen = (open: boolean) => {
    console.log(currentProductType);
    if (currentProductType != null) {
      setPageBannerOpenMap(
        (prev) => new Map([...prev.entries(), [currentProductType, open]]),
      );
    }
  };

  useEffect(() => {
    if (shouldBannerOpenOnInit) {
      setIsOpen(true);
    }
  }, [currentProductType]);

  const renderBannerContent = () => {
    const textStyles = {
      fontSize: '14px',
      maxWidth: '80%',
      display: 'inline-block',
      color: '#fff',
    };

    const linkStyles = { color: blue[300], textDecorationColor: blue[300] };

    const normalLoggedOutContent = (
      <Typography sx={{ ...textStyles }}>
        Viewing the free SpotGamma Preview. Have an account?{' '}
        <MUILink
          href={undefined}
          onClick={() => navigate('/login')}
          sx={{ ...linkStyles }}
        >
          Click here
        </MUILink>{' '}
        to login.
      </Typography>
    );

    const specialContent = (
      <Typography sx={{ ...textStyles }}>
        Welcome to our Open House,{' '}
        <MUILink
          href="https://spotgamma.com/subscribe-to-spotgamma/"
          target="_blank"
          sx={{ ...linkStyles }}
        >
          click here to register for 40% off
        </MUILink>{' '}
        your first year of SpotGamma.
      </Typography>
    );

    const tutorialContent = currentProductType && tutorialId && appcues && (
      <Typography sx={{ ...textStyles }}>
        <MUILink
          href={undefined}
          onClick={() => appcues.show(tutorialId)}
          sx={{ ...linkStyles }}
        >
          Click here
        </MUILink>{' '}
        for a quick guide on using {PRODUCT_LINKS[currentProductType].label} in
        your trading.
      </Typography>
    );

    const hiroDelayedContent = (
      <Typography sx={{ ...textStyles }}>
        Viewing delayed HIRO.{' '}
        <MUILink
          href="https://spotgamma.com/subscribe-to-spotgamma/"
          target="_blank"
          sx={{ ...linkStyles }}
        >
          Click here
        </MUILink>{' '}
        to upgrade to Alpha to get full access to HIRO.
      </Typography>
    );

    if (!currentProductType) {
      return null; // no need for banner on non-product pages
    }

    // completely logged out user
    if (userDetails == null) {
      // currently in valid special promo time
      if (isValidTokenOverrideTimeframe()) {
        return (
          <Stack sx={{ width: '100%', alignItems: 'center' }}>
            {specialContent}
            {tutorialContent}
          </Stack>
        );
      }

      if (currentProductType === ProductType.HIRO) {
        return hiroDelayedContent;
      }

      return normalLoggedOutContent;
    }

    // logged in user but without access to a product
    if (!productsWithAccess.has(currentProductType)) {
      // currently in valid special promo time
      if (isValidTokenOverrideTimeframe()) {
        return (
          <Stack sx={{ width: '100%', alignItems: 'center' }}>
            {tutorialContent}
          </Stack>
        );
      }

      if (currentProductType === ProductType.HIRO) {
        return hiroDelayedContent;
      }
    }

    return normalLoggedOutContent;
  };

  const renderedBanner = renderBannerContent();

  return (
    <Box
      sx={{
        height: isMobile
          ? undefined
          : `calc(100vh - ${isOpen ? '10px' : '120px'})`,
        display: 'flex',
        flexDirection: 'column',
        ...sx,
      }}
    >
      <Box sx={{ display: renderedBanner != null ? 'block' : 'none' }}>
        <PageBanner
          message={renderedBanner}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      </Box>
      {children}
    </Box>
  );
};
