import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import ReactGA from 'react-ga4';
import { CustomColorModeThemeProvider } from './CustomColorModeThemeProvider';
import { RecoilDebugObserver } from './RecoilDebugObserver';
import { isBloomberg, getEnv } from './util';
import { CookiesProvider } from 'react-cookie';
import { workerState } from './states';
import { endpointSymbol } from 'vite-plugin-comlink/symbol';

const comLinkWorker = new ComlinkWorker<typeof import('./PollingWorker')>(
  new URL('./PollingWorker', import.meta.url),
);

const rawWorker = comLinkWorker[endpointSymbol];

const workerInstance = { comLinkWorker, rawWorker };

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

if (import.meta.env.VITE_GA_TRACKING_ID != null) {
  ReactGA.initialize(import.meta.env.VITE_GA_TRACKING_ID, {});
}

const body = (
  <RecoilRoot
    initializeState={({ set }) => {
      set(workerState, workerInstance);
    }}
  >
    {getEnv() === 'development' &&
      import.meta.env.VITE_RECOIL_DEBUG === 'true' && <RecoilDebugObserver />}
    <CustomColorModeThemeProvider>
      <BrowserRouter>
        <CookiesProvider>
          <App worker={workerInstance} />
        </CookiesProvider>
      </BrowserRouter>
    </CustomColorModeThemeProvider>
  </RecoilRoot>
);

// MUI error suppression
const oldConsoleError = console.error;
console.error = (...args: any[]) => {
  if (
    typeof args[0] === 'string' &&
    args[0].includes('MUI: The `theme.spacing` array')
  ) {
    return;
  }
  return oldConsoleError(...args);
};

// StrictMode handling
root.render(
  isBloomberg() || import.meta.env.VITE_NO_STRICT_MODE === 'true' ? (
    body
  ) : (
    <React.StrictMode>{body}</React.StrictMode>
  ),
);

reportWebVitals();
