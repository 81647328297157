/* eslint-disable */
import './styles.css';
import { Navigate, Routes, Route, useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { ReactNode, useEffect, useState } from 'react';
import { GlobalStyles } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { ErrorBoundary } from 'react-error-boundary';
import {
  AdminPage,
  AlertsPage,
  Earnings,
  EquityHubPage,
  FoundersNotesPage,
  HiroPage,
  HomePage,
  ImpliedVolPage,
  IndicesPage,
  OpenInterestPage,
  PreferencesPage,
  ResourcesPage,
  ZeroHedge,
  ZeroHedgeHiro,
} from './pages';
import {
  appRefreshCounterState,
  autoRefreshState,
  bbgMarketSessionState,
  endQueryDateState,
  isMobileState,
  startQueryDateState,
  uncachedUserDetailsState,
  userDetailsState,
  workerState,
} from './states';
import dayjs from 'dayjs';
import { AppMetadata } from './AppMetadata';
import { DiscordSignup, ErrorScreen } from './components';
import { BloombergListener } from './components/bloomberg/BloombergListener';
import { ForceAuthRoute } from './components/core/ForceAuthRoute';
import { SGHtmlPopup } from './components/shared/SGHtmlPopup';
import PromoModal from './components/shared/Upsell/PromoModal';
import {
  SEEN_OH_UPSELL_KEY,
  HIRO_CHART_UPDATE_DELAY,
  PRODUCT_LINKS,
  OPEN_HOUSE_UPSELL,
} from './config';
import { useLog } from './hooks';
import useAuth from './hooks/auth/useAuth';
import usePollUpdate from './hooks/usePollUpdate';
import useRefresh from './hooks/useRefresh';
import { Layout } from './layouts';
import { AdminNotificationsPage } from './pages/AdminNotificationsPage';
import { IntegrationsPage } from './pages/IntegrationsPage';
import { LoginPage } from './pages/LoginPage';
import { StockScannerPage } from './pages/StockScannerPage';
import { TapePage } from './pages/TapePage';
import { TracePage } from './pages/TracePage';
import { ProductType } from './types';
import {
  isValidTokenOverrideTimeframe,
  isBloomberg,
  stockMarketOpen,
  preMarketOpen,
  getCurrentDate,
  isMarketOpenOnDate,
  getQueryDate,
  isBBEnvAvailable,
  connectToBbgMarketSession,
  isAdmin,
  getCachedToken,
} from './util';
import { RemotePollingWorker } from 'types/PollingWorker';

export const App = ({ worker }: { worker: RemotePollingWorker }) => {
  const setWorkerState = useSetRecoilState(workerState);
  const isMobile = useRecoilValue(isMobileState);
  const [autoRefreshTimer, setAutoRefreshTimer] =
    useRecoilState(autoRefreshState);
  const [endDate, setEndDate] = useRecoilState(endQueryDateState);
  const setStartDate = useSetRecoilState(startQueryDateState);
  const uncachedUserDetails = useRecoilValue(uncachedUserDetailsState);
  const userDetails = useRecoilValue(userDetailsState);
  const { validateCachedToken } = useAuth();
  const appRefreshCounter = useRecoilValue(appRefreshCounterState);
  const setBbgMarketSession = useSetRecoilState(bbgMarketSessionState);
  const [ohUpsellOpen, setOhUpsellOpen] = useState<boolean>(false);

  const theme = useTheme();
  const location = useLocation();
  const { triggerRefresh } = useRefresh();

  const { logError } = useLog('App');
  usePollUpdate();

  const globalStyles = {
    // Global styles using MUI's system
    html: {
      background: theme.palette.background.default,
    },
    '.MuiCalendarPicker-root button.MuiPickersArrowSwitcher-button': {
      color: theme.palette.text.primary,
    },
  };

  useEffect(() => {
    setTimeout(() => {
      const seenTimestamp = localStorage.getItem(SEEN_OH_UPSELL_KEY);
      if (
        (seenTimestamp == null ||
          dayjs().diff(dayjs(seenTimestamp), 'day') > 2) &&
        userDetails == null &&
        isValidTokenOverrideTimeframe()
      ) {
        setOhUpsellOpen(true);
      }
    }, 5000);
  }, []);

  const onOhUpsellOpen = (open: boolean) => {
    setOhUpsellOpen(open);
    if (!open) {
      localStorage.setItem(SEEN_OH_UPSELL_KEY, dayjs().toISOString());
    }
  };

  useEffect(() => {
    if (isBloomberg()) {
      return;
    }

    // If we detect that we're relying on cached userDetails and credentials,
    // ensure our token is still valid, otherwise clear it all out
    if (uncachedUserDetails == null && userDetails != null) {
      validateCachedToken();
    }
  }, [validateCachedToken, userDetails, uncachedUserDetails]);

  useEffect(() => {
    setWorkerState(worker);
  }, [worker, setWorkerState]);

  const scheduleAutoRefreshTimer = () => {
    // schedule for the next premarket or market open, whichever is earliest
    let cutoff =
      dayjs() >= stockMarketOpen() ? preMarketOpen() : stockMarketOpen();
    while (getCurrentDate() > cutoff || !isMarketOpenOnDate(cutoff)) {
      cutoff = cutoff.add(1, 'day');
    }

    const timeToCutoff = cutoff.diff(getCurrentDate());
    const refreshTimer = setTimeout(() => {
      const targetDate = getQueryDate();
      if (!endDate.isSame(targetDate, 'day')) {
        setStartDate(targetDate);
        setEndDate(targetDate);
      }
      setAutoRefreshTimer(undefined);
    }, timeToCutoff + HIRO_CHART_UPDATE_DELAY);
    setAutoRefreshTimer(refreshTimer);
  };

  useEffect(() => {
    if (autoRefreshTimer == null && userDetails != null) {
      scheduleAutoRefreshTimer();
    }
  }, [autoRefreshTimer, userDetails]);

  useEffect(() => {
    if (!isBBEnvAvailable()) {
      return;
    }

    let session: any = null;
    async function connectToSession() {
      session = await connectToBbgMarketSession();
      setBbgMarketSession(session);
      console.log('created session', session);
    }

    connectToSession();

    return () => {
      if (session) {
        session.destroy();
      }
    };
  }, []);

  const authed = (element: ReactNode) => (
    <ForceAuthRoute>{element}</ForceAuthRoute>
  );

  const renderRoutes = () => {
    return (
      <Routes>
        <Route path="/login" element={<LoginPage location={location} />} />
        {/* Routes that do not force login */}
        <Route path="/html/:category" element={<SGHtmlPopup />} />
        <Route path="/zerohedge" element={<ZeroHedge />} />
        <Route path="/zerohedge-hiro" element={<ZeroHedgeHiro />} />
        <Route path="/earnings" element={<Earnings />} />
        <Route path="/" element={<Layout />}>
          <Route
            path="/"
            element={<Navigate to={isBloomberg() ? `/hiro` : '/home'} />}
          />
          <Route
            path="/foundersNotes/preview/:previewKey"
            element={<FoundersNotesPage />}
            key="/foundersNotes/preview"
          />
          {/* Routes that do force login */}
          <Route path="/hiro" element={authed(<HiroPage />)} />
          <Route path="/resources" element={authed(<ResourcesPage />)} />
          <Route
            path="/resources/discord"
            element={authed(<DiscordSignup />)}
          />
          <Route path="/equityhub" element={authed(<EquityHubPage />)} />
          <Route path="/scanners" element={authed(<StockScannerPage />)} />
          <Route path="/home" element={authed(<HomePage />)} key="/home" />
          <Route path="/allMyAlerts" element={authed(<AlertsPage />)} />
          <Route path="/oi" element={<OpenInterestPage />} />
          <Route
            path="/foundersNotes"
            element={authed(<FoundersNotesPage />)}
            key="/foundersNotes"
          />
          <Route
            path="/foundersNotes/:id"
            element={authed(<FoundersNotesPage />)}
            key="/foundersNotes/:id"
          />
          <Route path="/tape" element={authed(<TapePage />)} key="/tape" />
          <Route
            path="/indices"
            element={authed(<IndicesPage />)}
            key="/indices"
          />
          <Route
            path={PRODUCT_LINKS[ProductType.INTEGRATIONS].link}
            element={authed(<IntegrationsPage />)}
            key={PRODUCT_LINKS[ProductType.INTEGRATIONS].link}
          />
          <Route
            path={PRODUCT_LINKS[ProductType.IMPLIED_VOL].link}
            element={authed(<ImpliedVolPage />)}
            key={PRODUCT_LINKS[ProductType.IMPLIED_VOL].link}
          />
          <Route
            path={PRODUCT_LINKS[ProductType.TRACE].link}
            element={<TracePage />}
            key={PRODUCT_LINKS[ProductType.TRACE].link}
          />
          {isAdmin(userDetails) && (
            <Route path="/admin" element={authed(<AdminPage />)} key="/admin" />
          )}
          {isAdmin(userDetails) && (
            <Route
              path="/admin/notifications"
              element={authed(<AdminNotificationsPage />)}
              key="/admin/notifications"
            />
          )}
          {getCachedToken() != null && (
            <Route path="/preferences" element={authed(<PreferencesPage />)} />
          )}
        </Route>

        {/*this catch-all needs to be the last route*/}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    );
  };

  try {
    return (
      <ErrorBoundary
        onError={(error) =>
          logError(error, 'ErrorBoundary', { stack: error.stack })
        }
        fallbackRender={({ error, resetErrorBoundary }) => (
          <ErrorScreen
            error={error}
            tryAgainCallback={() => {
              resetErrorBoundary();
              triggerRefresh();
            }}
          />
        )}
      >
        <div
          style={{
            height: isMobile ? undefined : '100vh',
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary,
          }}
          key={`app-container-${appRefreshCounter}`}
        >
          <GlobalStyles styles={globalStyles} />
          <AppMetadata location={location} worker={worker} />
          {renderRoutes()}
          {isBBEnvAvailable() && <BloombergListener />}
        </div>
        <PromoModal
          open={ohUpsellOpen}
          setOpen={onOhUpsellOpen}
          title={OPEN_HOUSE_UPSELL.title}
          subtitle={OPEN_HOUSE_UPSELL.subtitle}
          body={OPEN_HOUSE_UPSELL.body}
          actions={[
            {
              title: 'Sign Up Now for 40% Off',
              onClick: () =>
                window.open(
                  'https://spotgamma.com/subscribe-to-spotgamma/',
                  '_blank',
                ),
            },
            {
              title: 'Explore SpotGamma',
              onClick: () => onOhUpsellOpen(false),
              style: {
                backgroundColor: theme.palette.text.disabled,
                ':hover': {
                  backgroundColor: alpha(theme.palette.text.disabled, 0.35),
                },
              },
            },
          ]}
        />
      </ErrorBoundary>
    );
  } catch (err) {
    // second try/catch in case an error happens before ErrorBoundary is rendered
    return <ErrorScreen error={err as any} />;
  }
};
