import dayjs from 'dayjs';
import { useTheme } from '@mui/material/styles';
import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  Brush,
} from 'recharts';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useEffect, useState, useRef } from 'react';
import { RawRiskReversal, RiskReversal } from '../../../types';
import {
  DEFAULT_CHART_MARGINS,
  DEFAULT_X_AXIS_STYLES,
  DEFAULT_Y2_AXIS_STYLES,
  DEFAULT_Y_AXIS_STYLES,
} from '../../../config';
import { CircularProgress } from '@mui/material';
import useRiskReversal from '../../../hooks/equityhub/useRR';
import useBrushZoom from '../../../hooks/useBrushZoom';
import {
  selectedEquityState,
  rrInitialDataState,
  rrChartZoomConfigState,
} from '../../../states';
import {
  updateBrushZoomConfig,
  getUtcYMD,
  formatAsCompactNumber,
  formatAsCompactNumberCallback,
  getZoomConfigRefArea,
} from '../../../util';
import ChartWatermarkContainer from '../../shared/ChartWatermarkContainer';

export const RiskReversalGraph = () => {
  const ref = useRef<HTMLInputElement | null>(null);
  const theme = useTheme();
  const { getRiskReversal } = useRiskReversal();
  const selectedEquity = useRecoilValue(selectedEquityState);
  const [initialData, setInitialData] = useRecoilState(rrInitialDataState);
  const [zoomConfig, setZoomConfig] = useRecoilState(rrChartZoomConfigState);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { zoomChartConfig } = useBrushZoom<RiskReversal>(
    zoomConfig,
    setZoomConfig,
    'epoch_millis',
    initialData,
  );

  useEffect(() => {
    async function generateChartData() {
      setIsLoading(true);
      let data = await getRiskReversal();
      const transformedData = data
        .map(({ rr, trade_date, upx }: RawRiskReversal) => ({
          rr,
          epoch_millis: dayjs(trade_date).valueOf(),
          upx,
        }))
        .sort(
          (a: RiskReversal, b: RiskReversal) => a.epoch_millis - b.epoch_millis,
        );

      updateBrushZoomConfig(zoomConfig, transformedData, setZoomConfig);
      setInitialData(transformedData);
      setIsLoading(false);
    }
    generateChartData();
  }, [getRiskReversal, setZoomConfig, setInitialData]);

  return isLoading ? (
    <CircularProgress color="primary" />
  ) : (
    <ChartWatermarkContainer
      ref={ref}
      size={15}
      offsetX={50}
      offsetY={35}
      sym={selectedEquity?.sym}
      symStyles={{ right: '75px', top: '2px' }}
    >
      <ResponsiveContainer>
        <LineChart margin={DEFAULT_CHART_MARGINS} {...zoomChartConfig}>
          <CartesianGrid strokeDasharray="1 10" stroke={theme.palette.gray} />
          <XAxis
            allowDataOverflow
            label={{
              ...DEFAULT_X_AXIS_STYLES,
              value: 'Trade Date',
              offset: 3,
            }}
            dataKey="epoch_millis"
            domain={['dataMin', 'dataMax']}
            tick={{ fontSize: 10 }}
            tickFormatter={getUtcYMD}
            type="number"
            tickCount={15}
          />
          <Brush
            dataKey="epoch_millis"
            tickFormatter={getUtcYMD}
            startIndex={zoomConfig.leftIdx}
            endIndex={zoomConfig.rightIdx}
            onChange={(brushIndices: any) =>
              setZoomConfig((prev) => ({
                ...prev,
                leftIdx: brushIndices.startIndex,
                rightIdx: brushIndices.endIndex,
              }))
            }
            height={20}
            travellerWidth={15}
            stroke={theme.palette.gray}
            fill={theme.palette.background.paper}
            alwaysShowText
          />
          <YAxis
            allowDataOverflow
            yAxisId="left"
            tickFormatter={(v: number) => `$${formatAsCompactNumber(v)}`}
            tick={{ fontSize: 11 }}
            domain={['dataMin', 'dataMax']}
            type="number"
            label={{
              value: 'Price',
              ...DEFAULT_Y_AXIS_STYLES,
            }}
          />
          <YAxis
            allowDataOverflow
            yAxisId="right"
            tickFormatter={formatAsCompactNumberCallback}
            tick={{ fontSize: 11 }}
            domain={['dataMin', 'dataMax']}
            type="number"
            label={{
              value: 'Risk Reversal',
              ...DEFAULT_Y2_AXIS_STYLES,
            }}
            orientation="right"
          />
          <Tooltip
            formatter={(v: string, name: string) =>
              (name === 'Price' ? '$' : '') + parseFloat(v).toLocaleString()
            }
            labelFormatter={getUtcYMD}
            itemStyle={{ fontSize: '11px' }}
            contentStyle={{
              color: theme.palette.text.primary,
              border: 'none',
              backgroundColor: theme.palette.background.paper,
              boxShadow: theme.palette.shadows.paperBoxShadow,
            }}
            separator=": "
          />
          <Line
            yAxisId="left"
            dataKey="upx"
            name="Price"
            key="upx"
            dot={false}
            stroke={theme.palette.indices.tilt.upx}
          />
          <Line
            yAxisId="right"
            dataKey="rr"
            name="Risk Reversal"
            key="rr"
            dot={false}
            stroke={theme.palette.indices.tilt.delta}
          />
          {getZoomConfigRefArea(zoomConfig, 'left')}
        </LineChart>
      </ResponsiveContainer>
    </ChartWatermarkContainer>
  );
};
