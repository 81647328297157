import { dayjs } from '../../../../util/shared/date';
import { LineStyle } from 'lightweight-charts';
import { useTheme } from '@mui/material/styles';
// import { LineStyle } from "../../lightweight-charts.esm.development.js";
import Chart from './Chart';
import Series from './Series';
import PriceLine from './PriceLine';
import {
  isZerohedge,
  formatAsCompactNumber,
  isBBEnvAvailable,
  getSelectedLenses,
  getPriceLines,
  sigHighLow,
  getDateFormatted,
  calcOffsetMS,
} from '../../../../util';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import {
  hiroTimeState,
  timezoneState,
  lenseState,
  hiroViewAlertsState,
  hiroUpdatingTypeState,
  negativeTrendColorState,
  positiveTrendColorState,
  bottomCandlesTypeState,
  startQueryDateState,
  endQueryDateState,
  showChartTooltipState,
  todaysOpenArrState,
  hiroETHState,
  optionTypeState,
  userDetailsState,
  hiroChartTimeRangeState,
  isInstitutionalLocalState,
} from '../../../../states';
import useAlerts from '../../../../hooks/alerts/useAlerts';
import useHiroUpdating, {
  SERIES_DEFAULT_OPTS,
} from '../../../../hooks/hiro/useHiroUpdating';
import debounce from 'lodash.debounce';
import {
  HiroUpdatingType,
  HiroLense,
  HiroOptionType,
  BottomCandlesType,
  ProductType,
} from '../../../../types';
import { ChartTooltip } from '../../../bloomberg/ChartTooltip';
import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import {
  ALL_LENSES,
  LENSE_DISPLAY_TYPES,
  LENSE_LABELS,
} from '../../../../config';

const HIRO_PRICE_FMT = { type: 'volume' };

const ZOOM_MOVE_TRESHOLD_SECS = 30;

const isEmpty = (data) => {
  for (const k of ALL_LENSES) {
    for (const lense of LENSE_DISPLAY_TYPES) {
      if (data[k][lense].length > 0) {
        return false;
      }
    }
  }
  return true;
};

const HiroChart = (props) => {
  const {
    styling = {},
    sigKey,
    data,
    date,
    sym,
    showTotal,
    rollingSeconds,
    sgData,
  } = props;
  const theme = useTheme();

  const {
    backgroundColor = theme.palette.background.paper,
    textColor = theme.palette.text.primary,
  } = styling;

  const userDetails = useRecoilValue(userDetailsState);
  const isInstitutionalLocal = useRecoilValue(isInstitutionalLocalState);
  const [zoomTime, setZoomTime] = useRecoilState(hiroTimeState);
  const { getHiroMarkers } = useAlerts();
  const selectedLenseMap = useRecoilValue(lenseState);
  const showAlerts = useRecoilValue(hiroViewAlertsState);

  const [lastSym, setLastSym] = useState(null);
  const currentTimezone = useRecoilValue(timezoneState);
  const hiroUpdatingType = useRecoilValue(hiroUpdatingTypeState);
  const negativeTrendColor = useRecoilValue(negativeTrendColorState);
  const positiveTrendColor = useRecoilValue(positiveTrendColorState);
  const startDate = useRecoilValue(startQueryDateState);
  const endDate = useRecoilValue(endQueryDateState);
  const todaysOpenArr = useRecoilValue(todaysOpenArrState);
  const showExtendedHours = useRecoilValue(hiroETHState);
  const currOptionType = useRecoilValue(optionTypeState);
  const timezone = useRecoilValue(timezoneState);

  // ## STREAMING ONLY START ##
  const showChartTooltip = useRecoilValue(showChartTooltipState);
  const [isScrolling, setIsScrolling] = useState(false);
  const [isScrollingTimeout, setIsScrollingTimeout] = useState(undefined);
  const [tooltipProps, setTooltipProps] = useState(null);
  const bottomCandlesType = useRecoilValue(bottomCandlesTypeState);
  const [chartLoading, setChartLoading] = useState(false);
  // tooltip callback gets called very often, to the point where it will
  // set state multiple times before the state is actually updated. we need a ref to avoid crashing the ui
  const tooltipRef = useRef(null);
  // ## STREAMING ONLY END ##

  const [chartLayoutOptions, setChartLayoutOptions] = useState({
    background: {
      color: backgroundColor,
    },
    textColor,
  });

  const selectedLenses = getSelectedLenses(selectedLenseMap);
  const [api, setApi] = useState(null);
  const handleRef = useCallback(setApi, [setApi]);
  const priceSeriesRef = useRef(null);
  const setTimeRange = useSetRecoilState(hiroChartTimeRangeState);

  // TODO: There HAS to be a way to avoid this big map of refs to support
  // forwarding "updates" to TradingView series
  const seriesRefs = {
    all: {
      TOT: useRef(null),
      C: useRef(null),
      P: useRef(null),
      histogram: useRef(null),
    },
    nextExp: {
      TOT: useRef(null),
      C: useRef(null),
      P: useRef(null),
      histogram: useRef(null),
    },
    retail: {
      TOT: useRef(null),
      C: useRef(null),
      P: useRef(null),
      histogram: useRef(null),
    },
  };
  const priceLines = getPriceLines(
    sgData,
    theme,
    sigHighLow(sgData, todaysOpenArr, getDateFormatted(endDate)),
  );
  const getPropsForUpdating = () => {
    if (hiroUpdatingType === HiroUpdatingType.POLLING) {
      return {
        sym,
        seriesRefs,
        data,
        sigKey,
        rollingSeconds,
        priceSeriesRef,
        date,
        api,
      };
    }

    return {
      seriesRefs,
      api,
      priceSeriesRef,
      setChartLoading,
      sym,
      showPremarket: showExtendedHours,
      showPostmarket: showExtendedHours,
      onlyShowExtendedHoursForLatestDay: true,
      useUtcFakeOffset: true,
      startDate,
      endDate,
      selectedLenses: getSelectedLenses(selectedLenseMap),
      selectedOptionType: currOptionType,
      rollingSeconds,
      productType: ProductType.HIRO,
    };
  };

  const { getSeriesData, getPrices, getHistogramData, getSeriesExtraOpts } =
    useHiroUpdating(getPropsForUpdating());

  const getHistogramLense = () => {
    return selectedLenses.length === 1 ? selectedLenses[0] : HiroLense.All;
  };

  const getHistogram = () => {
    if (hiroUpdatingType === HiroUpdatingType.POLLING) {
      return null;
    }

    const lense = getHistogramLense();
    const data = getHistogramData(lense);

    if (data == null) {
      return null;
    }

    return (
      <Series
        ref={seriesRefs[lense].histogram}
        data={data}
        name="Histogram"
        priceScaleId=""
        type="histogram"
        priceFormat={{
          type: 'volume',
        }}
        overlay={true}
        scaleMargins={{
          top: 0.85,
          bottom: 0.0,
        }}
        priceScaleOptions={{
          scaleMargins: {
            top: 0.85,
            bottom: 0.0,
          },
        }}
      />
    );
  };

  useEffect(() => {
    setChartLayoutOptions({
      background: {
        color: backgroundColor,
      },
      textColor,
    });
  }, [backgroundColor, textColor]);

  useEffect(() => {
    if (sym === lastSym || api == null || zoomTime != null) {
      return;
    }
    const totalData = getSeriesData(selectedLenses[0], HiroOptionType.TOT);
    const len = totalData?.length ?? 0;
    api.timeScale().fitContent();
    if (len > 0) {
      api.timeScale().setVisibleLogicalRange({ from: 0, to: len - 1 });
    }

    // For streaming candles, there isnt a state that we can add to the useeffect dep to trigger this when streaming data loads
    // since streaming candles dont' use the 'data' object that polling uses
    // TODO: We probably want to refactor the streaming code to pass in a data prop similar to what polling does
    // for now though, add this condition to make sure this re-triggers when we load bloomberg data
    // so that we set the visible time range correctly
    if (hiroUpdatingType === HiroUpdatingType.STREAMING && len === 0) {
      return;
    }
    setLastSym(sym);
  }, [api, data, lastSym, selectedLenses, sym, zoomTime]);

  const tooltipCallback = useCallback(
    (data) => {
      if (isScrolling) {
        return;
      }

      const { hidden, param, container } = data;
      if (tooltipRef.current == null && hidden) {
        return;
      }
      if (hidden) {
        tooltipRef.current = null;
        setTooltipProps(null);
        return;
      }

      let time = param.time;
      if (tooltipRef.current != null && tooltipRef.current.time === time) {
        return;
      }
      // set it immediately to make duplicate simultaneous calls return
      tooltipRef.current = { time, displayData: [], containerWidth: 0 };

      let displayData = [];
      const priceData = param.seriesData.get(priceSeriesRef.current);
      if (priceData) {
        displayData.push({
          text: `Price: ${priceData.value.toFixed(2)}`,
          color: theme.palette.hiro.lenses.price.total,
        });
      }
      const histogramLense = getHistogramLense();
      if (histogramLense != null) {
        const histogramData = param.seriesData.get(
          seriesRefs[histogramLense].histogram.current,
        );
        if (histogramData) {
          let label, value, color;
          if (bottomCandlesType === BottomCandlesType.ABSOLUTE_DELTA) {
            label = `${LENSE_LABELS[histogramLense]} Absolute Delta`;
            value = histogramData.value;
            color = theme.palette.hiro.bottomCandles.absolute;
          } else {
            label = 'Net HIRO Value';
            value =
              negativeTrendColor === histogramData.color
                ? -1 * histogramData.value
                : histogramData.value;
            color = value < 0 ? negativeTrendColor : positiveTrendColor;
          }
          displayData.push({
            text: `${label}: ${formatAsCompactNumber(value)}`,
            color,
          });
        }
      }

      const lenseOptionToReadable = {
        TOT: 'Total',
        C: 'Call',
        P: 'Put',
      };

      for (const lense of selectedLenses) {
        (showTotal ? ['TOT'] : ['P', 'C']).forEach((optionType) => {
          const lenseData = param.seriesData.get(
            seriesRefs[lense][optionType].current,
          );
          if (lenseData == null) {
            return;
          }
          const readableOption = lenseOptionToReadable[optionType];
          const color =
            theme.palette.hiro.lenses[lense][readableOption.toLowerCase()];
          displayData.push({
            color,
            text: `${
              LENSE_LABELS[lense]
            } ${readableOption} HIRO Delta: ${formatAsCompactNumber(
              lenseData.close,
            )}`,
          });
        });
      }

      const tooltipData = {
        hidden,
        displayData,
        time,
        containerWidth: container.clientWidth,
        containerHeight: container.clientHeight,
      };
      tooltipRef.current = tooltipData;
      setTooltipProps(tooltipData);
    },
    [
      tooltipProps,
      selectedLenseMap,
      isScrolling,
      bottomCandlesType,
      showTotal,
      startDate,
      endDate,
      positiveTrendColor,
      negativeTrendColor,
    ],
  );

  const onWheel = useCallback(() => {
    // the tooltip can cause the chart to become laggy as the user scrolls
    // hide the tooltip until the scroll is complete
    setIsScrolling(true);
    clearTimeout(isScrollingTimeout);
    setIsScrollingTimeout(
      setTimeout(() => {
        setIsScrolling(false);
      }, 1000),
    );
  }, [isScrollingTimeout, isScrolling]);

  useEffect(() => {
    // TODO: investigate why going to the exact time of an alert crashes when running on the bloomberg terminal
    if (
      zoomTime == null ||
      api == null ||
      isBBEnvAvailable() ||
      isEmpty(data)
    ) {
      return;
    }
    const left = dayjs(zoomTime).subtract(10, 'minutes').valueOf();
    const right = dayjs(zoomTime).add(10, 'minutes').valueOf();
    // Displayed times have already been converted as though they were UTC.
    // So we have to do the same for our target timestamp.  i.e. turn 10AM ET
    // into 10AM UTC (a "lie")
    const offset = calcOffsetMS(currentTimezone, zoomTime);
    const from = Math.round((left + offset) / 1000);
    const to = Math.round((right + offset) / 1000);
    const timeScale = api.timeScale();
    api.timeScale().setVisibleRange({ from, to });

    // Detect a manual change of the zoom scale and clear out the zoom setting.
    const onTimeRangeChange = (newRange) => {
      if (newRange == null) {
        return;
      }
      const fromDist = Math.abs(newRange.from - from);
      const toDist = Math.abs(newRange.to - to);
      if (Math.max(fromDist, toDist) > ZOOM_MOVE_TRESHOLD_SECS) {
        setZoomTime(null);
      }
    };
    timeScale.subscribeVisibleTimeRangeChange(onTimeRangeChange);
    return () => {
      timeScale.unsubscribeVisibleTimeRangeChange(onTimeRangeChange);
    };
  }, [api, currentTimezone, data, selectedLenses, setZoomTime, zoomTime]);

  useEffect(() => {
    if (
      api == null ||
      (hiroUpdatingType === HiroUpdatingType.POLLING && isEmpty(data)) ||
      (userDetails?.isInstitutional !== false && isInstitutionalLocal !== false)
    ) {
      return;
    }
    // Create a debounced version of the handler
    const debouncedOnTimeRangeChange = debounce((newRange) => {
      if (newRange == null) {
        return;
      }
      const { from, to } = newRange;
      const fromMs = from * 1000;
      const toMs = to * 1000;

      // undo the hiro tz lies to get real utc
      const fromTime =
        dayjs.utc(fromMs).valueOf() - calcOffsetMS(currentTimezone, fromMs);
      const toTime =
        dayjs.utc(toMs).valueOf() - calcOffsetMS(currentTimezone, toMs);

      setTimeRange({
        from: fromTime,
        to: toTime,
      });
    }, 300); // 300ms debounce delay

    // Define the actual event handler
    const onTimeRangeChange = (newRange) => {
      debouncedOnTimeRangeChange(newRange);
    };

    api.timeScale()?.subscribeVisibleTimeRangeChange(onTimeRangeChange);

    // Cleanup on unmount or when 'api' changes
    return () => {
      api?.timeScale()?.unsubscribeVisibleTimeRangeChange(onTimeRangeChange);
      debouncedOnTimeRangeChange.cancel(); // Cancel any pending debounced calls
    };
  }, [
    api,
    data,
    hiroUpdatingType,
    currentTimezone,
    userDetails?.isInstitutional,
    setTimeRange,
  ]);

  if (chartLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  let len = getPrices().length;
  const timeScale = {
    timeVisible: true,
    secondsVisible: true,
    shiftVisibleRangeOnNewBar: true,
    logicalRange: [0, len - 1],
    fixLeftEdge: true,
    fixRightEdge: true, // false (desirable) prevents zooming out an arbitrary amount?
  };

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
      }}
      onWheel={showChartTooltip ? onWheel : undefined}
    >
      <Chart
        key={
          // changing key causes the Chart to re-render which it needs to for the tooltip toggling to work
          `showing-tooltip-${showChartTooltip}`
        }
        height={1}
        rightPriceScale={{
          autoScale: true,
          visible: true,
          scaleMargins: isZerohedge() ? { top: 0, bottom: 0 } : undefined,
        }}
        leftPriceScale={{
          visible: true,
          scaleMargins: isZerohedge()
            ? { top: 0, bottom: 0 }
            : { top: 0.3, bottom: 0.25 },
        }}
        ref={handleRef}
        layout={chartLayoutOptions}
        timeScale={timeScale}
        onTooltip={showChartTooltip ? tooltipCallback : undefined}
      >
        {
          <Series
            ref={priceSeriesRef}
            data={getPrices()}
            color={theme.palette.hiro.lenses.price.total}
            name="Price"
            priceScaleId={'left'}
            {...SERIES_DEFAULT_OPTS}
          >
            {!isZerohedge() &&
              Object.keys(priceLines)
                .filter((k) => priceLines[k]?.value != null)
                .map((k) => (
                  <PriceLine
                    key={k}
                    price={priceLines[k].value}
                    color={priceLines[k].color}
                    lineStyle={LineStyle.Dotted}
                    axisLabelVisible={true}
                    title={k}
                  />
                ))}
          </Series>
        }
        {getHistogram()}
        {selectedLenses.map((lense, lenseIdx) => {
          return showTotal ? (
            <Series
              ref={seriesRefs[lense].TOT}
              markers={showAlerts && lenseIdx === 0 ? getHiroMarkers() : []}
              key={`${lense}:total`}
              data={getSeriesData(lense, HiroOptionType.TOT, rollingSeconds)}
              color={theme.palette.hiro.lenses[lense].total}
              name="Total"
              priceScaleId={'right'}
              priceFormat={HIRO_PRICE_FMT}
              {...getSeriesExtraOpts(lense, HiroOptionType.TOT)}
            />
          ) : (
            <>
              <Series
                ref={seriesRefs[lense].C}
                key={`${lense}:calls`}
                markers={showAlerts && lenseIdx === 0 ? getHiroMarkers() : []}
                data={getSeriesData(lense, HiroOptionType.C, rollingSeconds)}
                color={theme.palette.hiro.lenses[lense].call}
                name="Calls"
                priceScaleId={'right'}
                priceFormat={HIRO_PRICE_FMT}
                lineStyle={styling[lense].style}
                {...getSeriesExtraOpts(lense, HiroOptionType.C)}
              />
              <Series
                ref={seriesRefs[lense].P}
                key={`${lense}:puts`}
                data={getSeriesData(lense, HiroOptionType.P, rollingSeconds)}
                name="Puts"
                color={theme.palette.hiro.lenses[lense].put}
                priceFormat={HIRO_PRICE_FMT}
                lineStyle={styling[lense].style}
                {...getSeriesExtraOpts(lense, HiroOptionType.P)}
              />
            </>
          );
        })}
        {showChartTooltip && !isScrolling && tooltipProps != null && (
          <ChartTooltip {...tooltipProps} />
        )}
      </Chart>
    </div>
  );
};

export default HiroChart;
