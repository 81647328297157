import { TOKEN } from './token';
import { cloneDeep, merge, isPlainObject } from 'lodash';
import { getCachedToken } from './auth';
import { isBloomberg } from '../bloomberg';
import { getEnv } from './common';
import { FetchAuxOptions, ErrorResponse } from '../../types';
import { getOverrideToken } from '../user';
import { USER_ENDPOINTS_BLACKLIST } from '../../config/shared';

export const DEFAULT_OPTS = Object.freeze({
  method: 'GET',
  crossDomain: true,
  Origin: '*',
  'X-Requested-With': 'XMLHttpRequest',
  headers: Object.freeze({
    'x-json-web-token': TOKEN,
    'Content-Type': 'application/json',
    'App-Type': isBloomberg() ? 'bloomberg' : 'web',
  }),
});

// Return an auth header with the token override or undefined
export function getOverrideHeader() {
  const override = getOverrideToken();
  return override ? getAuthHeader(override) : undefined;
}

export function getAuthHeader(sgBearerToken = getCachedToken()) {
  return sgBearerToken == null
    ? {}
    : { headers: { Authorization: `Bearer ${sgBearerToken}` } };
}

export function getVersionHeader() {
  return import.meta.env.VITE_VERSION_NUM == null
    ? {}
    : { headers: { Version: `${import.meta.env.VITE_VERSION_NUM}` } };
}

// Fetch raw response.  Don't parse JSON
export async function fetchRawAPI(
  endpoint: string,
  auxiliaryOptions: FetchAuxOptions = {},
): Promise<any> {
  const overrideToken = getOverrideToken();
  try {
    let currentHost =
      auxiliaryOptions.host ?? import.meta.env.VITE_SPOTGAMMA_API;
    if (
      getEnv() === 'development' &&
      (auxiliaryOptions?.local ||
        import.meta.env.VITE_ALL_LOCAL_FETCH === 'true')
    ) {
      currentHost = import.meta.env.VITE_LOCAL_API ?? currentHost;
    }
    const url = `${currentHost}/${endpoint}`;
    const opts = merge(
      { ...cloneDeep(DEFAULT_OPTS) },
      getAuthHeader(
        USER_ENDPOINTS_BLACKLIST.some((b) => endpoint.includes(b)) ||
          /login|admin|refresh/.test(endpoint)
          ? getCachedToken()
          : overrideToken ?? getCachedToken(),
      ),
      getVersionHeader(),
      auxiliaryOptions,
    );
    const response = await fetch(url, opts);
    if (response.status >= 300) {
      console.error(`${url} returned status: ${response.status}`);
      let text = null;
      try {
        text = await response.text();
      } catch (e) {}
      return {
        error: text ?? 'There was an error. Please try again.',
        status: response.status,
        response,
      };
    }
    return response;
  } catch (err: any) {
    const defaultMaxRetries = err.message?.includes('Failed to fetch') ? 1 : 0;
    const retryCounter = auxiliaryOptions.retryCounter ?? 0;
    if (retryCounter < (auxiliaryOptions?.maxRetries ?? defaultMaxRetries)) {
      return fetchRawAPI(endpoint, {
        ...auxiliaryOptions,
        retryCounter: retryCounter + 1,
      });
    }

    throw err;
  }
}

export async function fetchAPI(
  endpoint: string,
  auxiliaryOptions?: FetchAuxOptions,
): Promise<any> {
  let response = null;
  try {
    response = await fetchRawAPI(endpoint, auxiliaryOptions);
    return response.error
      ? response
      : auxiliaryOptions?.buffer
      ? await response.arrayBuffer()
      : await response.json();
  } catch (err: any) {
    return { error: err, status: response?.status, response };
  }
}

export const getPollingHeader = (interval: number) => ({
  headers: { 'Polling-Interval-ms': `${interval}` },
});

export const encodeURIJson = (payload: any) =>
  encodeURIComponent(JSON.stringify(payload));

export const isErrorResponse = (val: any): val is ErrorResponse =>
  isPlainObject(val) && val.error != null;
