import useStreaming, {
  INCLUDE_STREAMING_DATA_TYPE,
} from '../../hooks/bloomberg/streaming/useStreaming';
import {
  HiroLense,
  HiroOptionType,
  PriceCandleWithDateTime,
  ProductType,
  SubLevel,
  TraceParams,
} from '../../types';
import {
  convertTwelveCandles,
  dayjs,
  getDateFormatted,
  getHiro,
  getTzOffsetMs,
  getUserOverrideToken,
  isLatestTradingDay,
  predicateSearch,
} from '../../util';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import {
  oiHiroSymsState,
  oiIntradayFilterPrice,
  oiIntradayPriceBoundsState,
  oiPriceCandleDurationState,
  timezoneState,
  userSubLevelState,
} from '../../states';
import { useLog } from '../../hooks';
import { DefaultChartSettings } from '../../config';

const UPDATE_INTERVAL_MS = 10_000; // 10 secs

type useTraceStreamingProps = {
  timestamp: dayjs.Dayjs | null;
  timestamps: dayjs.Dayjs[];
  zoomData: any;
  meanSpot: number | null;
  traceParams: TraceParams;
};

export const useTraceStreaming = ({
  timestamp,
  timestamps,
  meanSpot,
  zoomData,
  traceParams,
}: useTraceStreamingProps) => {
  const userLevel = useRecoilValue(userSubLevelState);
  const hiroSymsMap = useRecoilValue(oiHiroSymsState);
  const candleDuration = useRecoilValue(oiPriceCandleDurationState);
  const filterPriceRecoilVal = useRecoilValue(oiIntradayFilterPrice);
  const tz = useRecoilValue(timezoneState);
  const priceBounds = useRecoilValue(oiIntradayPriceBoundsState);

  const [lastAddedCandleTs, setLastAddedCandleTs] = useState<
    number | undefined
  >();
  const [lastAddedHiroTs, setLastAddedHiroTs] = useState<number | undefined>();
  const [streamingApiLoading, setStreamingApiLoading] = useState(true);
  const [useTwelveData, setUseTwelveData] = useState(false);
  const [twelveCandles, setTwelveCandles] = useState<PriceCandleWithDateTime[]>(
    [],
  );
  const [twelveDataLoading, setTwelveDataLoading] = useState(false);
  const [boundsStr, setBoundsStr] = useState<string | null>(null);
  const [firstChartTs, setFirstChartTs] = useState<number | undefined>();
  const [lastChartTs, setLastChartTs] = useState<number | undefined>();

  const lastUpdateTimestampPrice = useRef(dayjs().valueOf());
  const lastUpdateTimestampHiro = useRef(dayjs().valueOf());
  const { fetchAPIWithLog, nonProdDebugLog } = useLog('useTraceStreaming');

  const { intradayDate, intradaySym, heatmapColorSettings } = traceParams;

  const isLatestTimestamp =
    timestamps.length > 0 &&
    timestamp != null &&
    timestamps[timestamps.length - 1].isSame(timestamp, 'second');
  const filterPrice = isLatestTimestamp ? false : filterPriceRecoilVal;

  const hiroSym = useMemo(
    () => hiroSymsMap?.get(intradaySym) ?? intradaySym,
    [hiroSymsMap, intradaySym],
  );

  const { getPrices, getRolledCandles } = useStreaming(true, {
    productType: ProductType.TRACE,
    selectedLenses: [HiroLense.All],
    selectedOptionType: 'total',
    setChartLoading: setStreamingApiLoading,
    includeStreamingData:
      INCLUDE_STREAMING_DATA_TYPE.PRICE | INCLUDE_STREAMING_DATA_TYPE.HIRO,
    priceUpdateCallback: (_price, _lastPrice) => {
      const now = dayjs().valueOf();
      if (now - lastUpdateTimestampPrice.current < UPDATE_INTERVAL_MS) {
        return;
      }
      // updates every UPDATE_INTERVAL_MS
      // note that you will not be able to accurately access states here, only set them
      // this is because this is put in a socket onmessage handler which only gets set once on socket creation time
      // and does not update as state updates

      // need the ... here to clone getPrices so candles get set properly.
      // since getPrices uses a ref, without it, React would not recognize the change
      lastUpdateTimestampPrice.current = now;
      setLastAddedCandleTs(now);
    },
    hiroUpdateCallback: (_latestHiroVal: any, lense, option) => {
      const now = dayjs().valueOf();
      if (
        lense !== HiroLense.All ||
        option !== HiroOptionType.TOT ||
        now - lastUpdateTimestampHiro.current < UPDATE_INTERVAL_MS
      ) {
        return;
      }

      lastUpdateTimestampHiro.current = now;
      setLastAddedHiroTs(now);
    },
    sym: hiroSym.length > 0 ? hiroSym : intradaySym,
    candleDurationSecs: candleDuration,
    showPremarket: true,
    showPostmarket: false,
    useCandlesForPrice: true,
    useDayjs: true,
    useUtcFakeOffset: false,
    startDate: intradayDate,
    endDate: intradayDate,
    token: getUserOverrideToken(userLevel === SubLevel.ALPHA) ?? undefined,
    rollingSeconds: DefaultChartSettings.sumWindow,
  });

  const getCandlesArr = () =>
    (useTwelveData ? twelveCandles : [...getPricesFiltered()]) ?? [];

  const getPricesFiltered = () => {
    const prices = getPrices();
    if (firstChartTs == null || prices == null) {
      return [];
    }

    const candles = [...prices];
    const firstIdx =
      predicateSearch(
        candles,
        (candle) => candle.time < firstChartTs! / 1_000,
      ) + 1;
    return candles.slice(firstIdx);
  };

  const getHiroFiltered = () => {
    if (firstChartTs == null || lastChartTs == null || hiroSym.length == 0) {
      return [];
    }
    const candles = [
      ...getRolledCandles(
        HiroLense.All,
        HiroOptionType.TOT,
        DefaultChartSettings.sumWindow,
      ),
    ];
    const firstIdx =
      predicateSearch(
        candles,
        (candle) => candle.time < firstChartTs! / 1_000,
      ) + 1;
    const lastIdx = predicateSearch(
      candles,
      (candle) => candle.time < lastChartTs! / 1_000,
    );
    return candles.slice(firstIdx, lastIdx + 1);
  };

  // need to be able to add candles in a useEffect/useMemo dep
  // we dont need a usememo here as we dont actually want to use a usememo
  // to render, since it causes a noticeable chart lag
  // instead we use getCandlesArr() directly, since that uses a ref
  // and we pass in the deps here in any useeffect/memo that depends on getCandlesArr()
  const candlesDeps = [
    useTwelveData,
    twelveCandles,
    timestamp,
    lastAddedCandleTs,
    lastAddedHiroTs,
    streamingApiLoading,
    tz,
    firstChartTs,
    lastChartTs,
    filterPrice,
    hiroSym,
  ];

  const hiroChartData = useMemo(() => {
    const hiroCandles = getHiroFiltered();
    return getHiro(
      hiroCandles,
      filterPrice ? timestamp?.valueOf() : undefined,
      getTzOffsetMs(tz),
      heatmapColorSettings.hiroColor,
      hiroSym,
    );
  }, [heatmapColorSettings, ...candlesDeps]);

  const getLastCandle = (): PriceCandleWithDateTime | undefined => {
    const candles = getCandlesArr();
    let idx = candles.length - 1;
    if (timestamp != null && !isLatestTimestamp) {
      const tgt = timestamp.valueOf();
      idx = predicateSearch(candles, (c) => c.datetime.valueOf() <= tgt);
    }
    nonProdDebugLog(`using last directional filter candle`, candles[idx]);
    return candles[idx];
  };

  useEffect(() => {
    if (!useTwelveData) {
      return;
    }

    fetchTwelveDataCandles();
  }, [useTwelveData, candleDuration, intradaySym]);

  useEffect(() => {
    if (useTwelveData) {
      return;
    }

    if (
      !streamingApiLoading &&
      getPrices() != null &&
      getPrices().length === 0 &&
      !isLatestTradingDay(intradayDate)
    ) {
      // if we've loaded no candles and the date selected is not the latest trading day, fetch from twelve data
      setUseTwelveData(true);
      return;
    }
  }, [streamingApiLoading, useTwelveData]);

  const fetchTwelveDataCandles = async () => {
    setTwelveDataLoading(true);
    nonProdDebugLog('fetching twelve data...');
    const startDate = intradayDate;
    const endDate = startDate.add(1, 'day');
    const seriesParams = new URLSearchParams({
      symbol: encodeURIComponent(intradaySym),
      start_date: getDateFormatted(startDate),
      end_date: getDateFormatted(endDate),
      interval: `${candleDuration / 60}min`,
      order: 'ASC',
    });
    const series = await fetchAPIWithLog(
      `v1/twelve_series?${seriesParams.toString()}`,
    );
    const result = series[Object.keys(series)[0]];
    const newCandles = convertTwelveCandles(
      result.values,
      result.meta.exchange_timezone,
    );
    setTwelveCandles(newCandles ?? []);
    setTwelveDataLoading(false);
  };

  useEffect(() => {
    setUseTwelveData(false);
  }, [intradayDate]);

  useEffect(() => {
    const candles = getCandlesArr();
    // it's likely that the price bounds will stay the same for most of the day except when we make a new high and low
    // rather than recalculate the contour data on every candles change because of the bounds
    // let's save the bounds in a string (not array, since setting that will always re-trigger a re-render regardless)
    // and only have the contour data re-render if the bounds actually change
    if (priceBounds == null || (!(candles.length > 0) && meanSpot == null)) {
      setBoundsStr(null);
      return;
    }
    let lo = Infinity;
    let hi = -Infinity;
    if (candles.length > 0) {
      for (const candle of candles) {
        lo = Math.min(lo, candle.low);
        hi = Math.max(hi, candle.high);
      }
    } else {
      lo = hi = meanSpot!;
    }
    const newBounds = [lo * (1 - priceBounds), hi * (1 + priceBounds)].join(
      ',',
    );

    setBoundsStr(newBounds);
    nonProdDebugLog(`using new price bounds`, newBounds);
  }, [meanSpot, priceBounds, zoomData, ...candlesDeps]);

  const pricesLoading = streamingApiLoading || twelveDataLoading;

  return {
    candlesDeps,
    getCandlesArr,
    hiroChartData,
    getHiroFiltered,
    getLastCandle,
    pricesLoading,
    hiroSym,
    setFirstChartTs,
    setLastChartTs,
    boundsStr,
    filterPrice,
  };
};
