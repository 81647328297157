import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, ButtonGroup, Typography, useTheme } from '@mui/material';
import { useRecoilValue } from 'recoil';
import * as arrow from 'apache-arrow';
import { Plot } from './Plot';
import {
  TraceLense,
  TraceStrikeBarType,
  PriceLineKey,
  ProcessingState,
  ProductType,
  TraceGreek,
  ZoomData,
} from '../../types';
import { ExpandableContentWrapper, Loader } from '../../components';
import { Center } from '../../components/shared/Center';
import {
  getCandles,
  getGreekValueAtLastPrice,
  getHiroRange,
  getYRange,
  parseContourArray,
  shouldShowAxisLabels,
  strikeBarParquetUrlForType,
} from '../../util/oi';
import { TraceControls } from './TraceControls';
import { useLog, useSetSym } from '../../hooks';
import {
  dayjs,
  formatAsCompactNumber,
  getDateFormatted,
  getPriceLines,
  getSgData,
  getTzOffsetMs,
  hexToRGBA,
  predicateSearch,
  sigHighLow,
} from '../../util';
import { TRACE_BAR_TITLES, TraceShowChartType } from '../../config/oi';
import { TraceTimeSlider } from './TraceTimeSlider';
import Plotly, { Image } from 'plotly.js';
import { useStrikeBars } from '../../hooks/trace/useStrikeBars';
import { useTraceStreaming } from '../../hooks/trace/useTraceStreaming';
import { useContourData } from '../../hooks/trace/useContourData';
import { useTooltip } from '../../hooks/trace/useTooltip';
import { useStats } from '../../hooks/trace/useStats';
import { usePolling } from '../../hooks/trace/usePolling';
import { useTraceParams } from '../../hooks/trace/useTraceParams';
import useUserDetails from '../../hooks/user/useUserDetails';
import {
  oiIntradayParquetKeys,
  oiIntradayPriceBoundsState,
  timezoneState,
  screenWidthWithoutSidebarState,
  screenHeightState,
  isMobileState,
  oiStrikeBarTypeState,
  oiFullscreenState,
  todaysOpenArrState,
  oiShowKeyLevelsState,
  oiShowGexZeroDteState,
  oiShowColorScaleState,
  oiShowContourLinesState,
} from '../../states';

const END_CHART_X_PADDING = 50;

type TraceProps = {
  productType: ProductType;
};

export const Trace = ({ productType }: TraceProps) => {
  const theme = useTheme();
  const { saveSgSettings } = useUserDetails();
  const { getParam, searchParams, setParams } = useSetSym();

  const parquetKeys = useRecoilValue(oiIntradayParquetKeys);
  const priceBounds = useRecoilValue(oiIntradayPriceBoundsState);
  const tz = useRecoilValue(timezoneState);
  const screenWidth = useRecoilValue(screenWidthWithoutSidebarState);
  const screenHeight = useRecoilValue(screenHeightState);
  const isMobile = useRecoilValue(isMobileState);
  const strikeBarType = useRecoilValue(oiStrikeBarTypeState);
  const fullscreen = useRecoilValue(oiFullscreenState);
  const todaysOpenArr = useRecoilValue(todaysOpenArrState);
  const showKeyLevels = useRecoilValue(oiShowKeyLevelsState);
  const showGexZeroDte = useRecoilValue(oiShowGexZeroDteState);
  const showColorScale = useRecoilValue(oiShowColorScaleState);
  const showContourLines = useRecoilValue(oiShowContourLinesState);

  const { nonProdDebugLog } = useLog('Trace');

  const [sgData, setSgData] = useState<any>(null);

  const [gammaTable, setGammaTable] = useState<arrow.Table | undefined>();
  const [deltaTable, setDeltaTable] = useState<arrow.Table | undefined>();
  const [zoomData, setZoomData] = useState<ZoomData | undefined>();

  const [showChartType, setShowChartType] = useState(
    isMobile ? TraceShowChartType.Heatmap : TraceShowChartType.Both,
  );

  const traceParams = useTraceParams({ productType });
  const {
    intradayDate,
    intradaySym,
    selectedLense,
    selectedGreek,
    heatmapColorSettings,
  } = traceParams;

  const setTable = (
    stateSetter: (
      prevState: arrow.Table | undefined,
    ) => arrow.Table | undefined,
    greek: TraceGreek,
  ) => {
    const newTable = stateSetter(
      greek === TraceGreek.Delta ? deltaTable : gammaTable,
    );
    if (greek === TraceGreek.Delta) {
      setDeltaTable(newTable);
    } else {
      setGammaTable(newTable);
    }
  };

  const getTable = useCallback(
    () => (selectedGreek === TraceGreek.Delta ? deltaTable : gammaTable),
    [selectedGreek, deltaTable, gammaTable],
  );

  // set the params initially so that the url is shareable from the get-go
  useEffect(() => {
    setParams({
      lense: selectedLense,
      date: getDateFormatted(intradayDate),
      traceSym: intradaySym,
    });
  }, []);

  const timestamps = useMemo(() => {
    const table = getTable();
    if (table == null) {
      return [];
    }
    const ts = new Set(table.toArray().map((e) => e.timestamp));
    return [...ts].sort().map((t) => dayjs.utc(t).tz(tz));
  }, [selectedGreek, getTable, tz]);

  const timestampParam = useMemo(() => getParam('ts'), [searchParams]);

  const timestamp = useMemo(() => {
    if (timestampParam != null) {
      const ts = dayjs(timestampParam);

      if (
        ts.isValid() &&
        (timestamps.length === 0 || timestamps.find((time) => time.isSame(ts)))
      ) {
        return ts;
      }
    }

    return timestamps.length > 0 ? timestamps[timestamps.length - 1] : null;
  }, [timestamps, timestampParam]);

  const setTimestamp = (newVal: dayjs.Dayjs | null) => {
    setParams({ ts: newVal?.format() });
  };

  const meanSpot = useMemo(() => {
    const table = getTable();
    // Grab the last timestamp in our payload and get the mean spot price
    const array = table?.toArray();
    if (array == null) {
      return null;
    }
    const lastTS = array[array.length - 1].timestamp;
    const start = predicateSearch(array, (e) => e.timestamp < lastTS!) + 1;
    let end = predicateSearch(array, (e) => e.timestamp <= lastTS!);
    end = Math.max(0, end);
    return start > end ? null : Number(array[start].spot + array[end].spot) / 2;
  }, [selectedGreek, getTable]);

  // if we have space, keep some x padding if possible
  const width = screenWidth * (isMobile ? 1 : 0.95);
  // TODO: make height calculation dynamic and not hardcoded
  let height = isMobile
    ? screenHeight - (fullscreen ? 220 : 350)
    : screenHeight * 0.95 -
      172 -
      (fullscreen ? 0 : 50) - // (top bar + title)
      (productType === ProductType.INTERNAL_OPEN_INTEREST ? 100 : 0); // extra row of settings
  height -= fullscreen ? 35 : 70; // support button

  const {
    getHiroFiltered,
    getCandlesArr,
    hiroChartData,
    candlesDeps,
    getLastCandle,
    pricesLoading,
    hiroSym,
    setFirstChartTs,
    setLastChartTs,
    boundsStr,
    filterPrice,
  } = useTraceStreaming({
    timestamps,
    timestamp,
    meanSpot,
    zoomData,
    traceParams,
  });

  const {
    contourData,
    processingState,
    fetchAllParquets,
    minY,
    maxY,
    minX,
    maxX,
    triggerUpdate: triggerContourUpdate,
  } = useContourData({
    getTable,
    timestamp,
    candlesDeps,
    getLastCandle,
    setTimestamp,
    setTable,
    width,
    timestamps,
    boundsStr,
    traceParams,
  });

  useEffect(() => {
    setFirstChartTs(minX);
    setLastChartTs(maxX);
  }, [minX, maxX]);

  const {
    strikeBarsData,
    strikeBarsTracker,
    strikeBarsRange,
    strikeBarsLoading,
    setStrikeBarsLoading,
    triggerUpdate: triggerStrikeBarsUpdate,
    showZeroLine,
  } = useStrikeBars({
    timestamp,
    timestamps,
    minY,
    maxY,
    height,
    traceParams,
  });

  const { lastUpdateCheckAt } = usePolling({
    latestContourTs: contourData?.latestTimestamp,
    latestStrikeBarTs: strikeBarsData?.latestTimestamp,
    triggerContourUpdate,
    triggerStrikeBarsUpdate,
    selectedGreek,
    intradayDate,
  });

  const { statsDataMap } = useStats({ intradaySym, intradayDate });

  const { onHover, hoverInfo, setHoverInfo, crosshairsChartData } = useTooltip({
    selectedLense,
    intradaySym,
    intradayDate,
    heatmapColorSettings,
    strikeBarsTracker,
    strikeBarsData,
    statsDataMap,
    getHiroFiltered,
    getCandlesArr,
    hiroSym,
    minY,
    maxY,
    minX,
    maxX,
  });

  const fetchSgData = async () => {
    // not worth showing a loading spinner, but invalidate prior sg data first so we dont show incorrect levels
    setSgData(null);
    const newData = await getSgData(intradayDate, intradaySym);
    setSgData(newData);
  };

  useEffect(() => {
    if (showKeyLevels) {
      fetchSgData();
    }
  }, [intradayDate, showKeyLevels]);

  const gammaAtLastPriceUninverted = useMemo<number | undefined>(() => {
    const lastCandle = getLastCandle();
    if (gammaTable == null || lastCandle == null) {
      return undefined;
    }

    const { y, z, rawChartTimes } = parseContourArray(
      gammaTable.toArray(),
      timestamp?.valueOf(),
      parquetKeys,
      false, // we want uninverted
      null, // we always want to look at all the data
      getTzOffsetMs(tz),
      TraceLense.GAMMA,
    );

    return getGreekValueAtLastPrice(
      y,
      z,
      rawChartTimes,
      lastCandle,
      TraceLense.GAMMA,
      nonProdDebugLog,
    );
  }, [parquetKeys, timestamp, gammaTable, ...candlesDeps]);

  const levelsChartData = useMemo<{
    chartData: any[];
    annotations: any[];
  }>(() => {
    const contourX = contourData?.chartData?.x ?? [];
    if (
      sgData == null ||
      contourData == null ||
      contourX.length === 0 ||
      !showKeyLevels ||
      showChartType === TraceShowChartType.StrikeBars
    ) {
      return { chartData: [], annotations: [] };
    }

    // get the min and max x coordinates for the chart since the lines we draw will need them so they appear
    // as horizontal lines
    const x1 = contourX[0];
    const x2 = contourX[contourX.length - 1];
    // always show the labels at 6 hours past the first coordinate
    // TODO: IMPROVE THIS IN FUTURE AND PLACE IT IN A BETTER LOCATION THAN 6 HOURS AHEAD
    const xMid = dayjs(x1).add(6, 'hour');

    // don't show levels that are not within the strike ranges
    const contourStrikes = contourData.chartData.y;
    const minY = contourStrikes[0];
    const maxY = contourStrikes[contourStrikes.length - 1];

    const priceLines = getPriceLines(
      sgData,
      theme,
      sigHighLow(sgData, todaysOpenArr, getDateFormatted(intradayDate)),
    );
    const chartData: any[] = [];
    const annotations: any[] = [];

    Object.keys(priceLines).forEach((priceLineName) => {
      const levelData = priceLines[priceLineName as PriceLineKey];
      const val = levelData?.value ?? 0;
      if (val < minY || val > maxY) {
        return;
      }

      chartData.push({
        x: [x1, x2],
        y: [val, val],
        xaxis: 'x',
        yaxis: 'y',
        mode: 'lines',
        line: {
          color: levelData.color,
          width: 1,
        },
        name: priceLineName,
        hoverinfo: 'y',
      });

      // find the leftmost annotation with an overlapping y val
      let neighbor: any = null;
      for (const annotation of annotations) {
        if (Math.abs(annotation.y - val) <= 10) {
          if (neighbor == null || neighbor.x > annotation.x) {
            neighbor = annotation;
          }
        }
      }

      // subtract one hour from the left overlapping annotation label to ensure no overlap
      const xCoord =
        neighbor != null ? neighbor.x - 60 * 60 * 1000 : xMid.valueOf();

      annotations.push({
        x: xCoord,
        y: val,
        text: priceLineName,
        font: {
          size: 11,
          color: '#000000',
        },
        showarrow: false,
        bgcolor: hexToRGBA(levelData.color, 0.65),
        borderpad: 4,
      });
    });
    nonProdDebugLog('using annotations', annotations);
    return { chartData, annotations };
  }, [
    contourData,
    sgData,
    todaysOpenArr,
    theme,
    showKeyLevels,
    intradayDate,
    showChartType,
  ]);

  const loading =
    pricesLoading ||
    (processingState !== ProcessingState.DONE &&
      processingState !== ProcessingState.FAILED_FETCH);

  // strike bars are out of date if youre selecting the latest timestamp but the latest timestamp
  // we have for the strike bars is < than that
  const strikeBarsOutOfDate =
    timestamps.length > 0 &&
    (timestamp?.valueOf() ?? 0) ===
      timestamps[timestamps.length - 1].valueOf() &&
    (strikeBarsData?.latestTimestamp ?? Infinity) <
      timestamps[timestamps.length - 1].valueOf();

  const gexTitle = () => {
    if (isMobile) {
      return undefined;
    }

    const title = TRACE_BAR_TITLES.get(strikeBarType)!;
    if (
      showGexZeroDte &&
      (strikeBarsData?.chartData?.length ?? 0) > 0 &&
      strikeBarsData!.chartData[0].x.find((v: number) => v !== 0) == null
    ) {
      return `${title}<br />(market closed, 0DTE n/a)`;
    }

    if (strikeBarsOutOfDate) {
      return `${title}<br />(out of date, updating...)`;
    }

    return title;
  };

  const onZoom = useCallback(
    (eventData: any) => {
      const xZoomedOut = eventData['xaxis.autorange'];
      const yZoomedOut = eventData['yaxis.autorange'];

      const xBounds = [
        eventData['xaxis.range[0]'],
        eventData['xaxis.range[1]'],
      ].filter((d) => d != null);
      const yBounds = [eventData['yaxis.range[0]'], eventData['yaxis.range[1]']]
        .filter((d) => d != null)
        .map((d) => Math.floor(d));

      const y2Bounds = [
        eventData['yaxis2.range[0]'],
        eventData['yaxis2.range[1]'],
      ]
        .filter((d) => d != null)
        .map((d) => Math.floor(d));

      const newZoomData: ZoomData = {};
      // for some reason, plotly sometimes does not pass in autorange for the y2 axis.
      // so we dont always know when y2 is zoomed out. instead, use the autorange for y
      if (!yZoomedOut) {
        newZoomData.y = yBounds.length > 0 ? yBounds : zoomData?.y;
        newZoomData.y2 = y2Bounds.length > 0 ? y2Bounds : zoomData?.y2;
      }
      if (!xZoomedOut) {
        newZoomData.x = xBounds.length > 0 ? xBounds : zoomData?.x;
      }
      const newZoomDataState =
        Object.keys(newZoomData).length > 0 ? newZoomData : undefined;
      nonProdDebugLog('new zoom data', newZoomDataState);
      setZoomData(newZoomDataState);
    },
    [zoomData],
  );

  const xAxisStartDomain = isMobile
    ? 0.15
    : strikeBarType === TraceStrikeBarType.NONE ||
      showChartType === TraceShowChartType.StrikeBars
    ? 0.05
    : 0.27;
  // ensure there is fixed padding at the end regardless of chart width
  const xAxisEndDomain = isMobile ? 1 : 1 - END_CHART_X_PADDING / width;
  const xAxis2Domain =
    showChartType === TraceShowChartType.StrikeBars
      ? [xAxisStartDomain, xAxisEndDomain]
      : [0, 0.19];

  const lastCandlesChartData = useMemo<{
    chartData: any[];
    annotations: any[];
  }>(() => {
    const contourX = contourData?.chartData?.x ?? [];
    const priceLastCandle = getLastCandle();
    const chartData: any[] = [];
    const annotations: any[] = [];

    if (contourX.length === 0) {
      return { chartData, annotations };
    }

    const hiroYArr = (hiroChartData?.chartData as any)?.y ?? [];
    const hiroLastClose = hiroYArr[hiroYArr.length - 1];

    const x1 = contourX[0];
    const x2 = contourX[contourX.length - 1];
    const opacity = 0.6;

    if (priceLastCandle != null) {
      const close = Math.round(priceLastCandle.close);
      const color = hexToRGBA(heatmapColorSettings.lastCandleColor, opacity);

      chartData.push({
        x: [x1, x2],
        y: [close, close],
        xaxis: 'x',
        yaxis: 'y',
        mode: 'lines',
        line: {
          dash: 'dot',
          color,
          width: 0.5,
        },
        name: close,
        hoverinfo: 'y',
      });

      annotations.push({
        x: xAxisStartDomain - 0.01,
        xanchor: 'right',
        xref: 'paper',
        y: close,
        text: `${close}`,
        font: {
          size: 11,
          color: theme.palette.background.default,
        },
        showarrow: false,
        bgcolor: theme.palette.text.primary,
        opacity,
        borderpad: 2,
      });
    }

    if (hiroLastClose != null) {
      const color = hexToRGBA(heatmapColorSettings.hiroColor, opacity);

      chartData.push({
        x: [x1, x2],
        y: [hiroLastClose, hiroLastClose],
        xaxis: 'x',
        yaxis: 'y2',
        mode: 'lines',
        line: {
          dash: 'dot',
          color,
          width: isMobile ? 2 : 0.5, // needs to be thicker to be visible on mobile
        },
        name: hiroLastClose,
        hoverinfo: 'y',
      });

      annotations.push({
        x: xAxisEndDomain + 0.01,
        xanchor: 'left',
        xref: 'paper',
        y: hiroLastClose,
        yaxis: 'y2',
        yref: 'y2',
        text: `${formatAsCompactNumber(hiroLastClose)}`,
        font: {
          size: 11,
          color: `#fff`,
        },
        showarrow: false,
        bgcolor: heatmapColorSettings.hiroColor,
        borderpad: 2,
      });
    }

    return { chartData, annotations };
  }, [
    ...candlesDeps,
    contourData,
    isMobile,
    heatmapColorSettings,
    hiroChartData,
  ]);

  const getCandlesData = () => {
    const candles = getCandlesArr();
    if (candles.length === 0) {
      return {};
    }

    return getCandles(
      candles,
      filterPrice ? timestamp?.valueOf() : undefined,
      getTzOffsetMs(tz),
      heatmapColorSettings,
      minX,
      maxX,
    ) as any;
  };

  const getPlotlyData = () => {
    const heatmapData = [
      getCandlesData(),
      contourData?.chartData
        ? {
            ...contourData.chartData,
            showscale: showColorScale && !isMobile,
            line: {
              smoothing: 0.9,
              width: showContourLines ? undefined : 0,
              color: heatmapColorSettings.contourLineColor,
            },
          }
        : null,
      ...levelsChartData.chartData,
      hiroChartData?.chartData,
      ...lastCandlesChartData.chartData,
      ...crosshairsChartData,
    ].filter((d) => d != null);

    let gexChartData = [];
    let trackerData: Plotly.Data[] = [];

    if (strikeBarType !== TraceStrikeBarType.NONE) {
      gexChartData =
        (strikeBarsData?.chartData?.length ?? 0) > 0 && !strikeBarsLoading
          ? strikeBarsData!.chartData
          : // needed for it to show loading even without data
            [{ xaxis: 'x2', type: 'bar', visible: false }];

      if (gexChartData && gexChartData[0].x && gexChartData[0].y) {
        trackerData = strikeBarsData?.trackerData ?? [];
      }
    }

    const strikeBarsDisplayData = [...trackerData, ...gexChartData].filter(
      (d) => d != null,
    );

    let chartData;
    let annotations = levelsChartData.annotations;

    if (showChartType === TraceShowChartType.Both) {
      chartData = heatmapData.concat(strikeBarsDisplayData);
      annotations = annotations.concat([
        ...lastCandlesChartData.annotations,
        ...(strikeBarsData?.annotations ?? []),
      ]);
    } else if (showChartType === TraceShowChartType.Heatmap) {
      chartData = heatmapData;
      annotations = annotations.concat(lastCandlesChartData.annotations);
    } else {
      chartData = strikeBarsDisplayData;
      annotations = strikeBarsData?.annotations ?? [];
    }

    return { chartData, annotations };
  };

  // if we have zoomData below, the array is just 2 items, the min and max
  const watermarkXArr = zoomData?.x
    ? zoomData.x.map((str) => dayjs(str))
    : contourData?.chartData?.x;
  const watermarkYArr = zoomData?.y ?? contourData?.chartData?.y;
  const watermarkInverseSize = isMobile ? 5 : 10; // lower value means bigger
  const watermark: Partial<Image> =
    showChartType === TraceShowChartType.StrikeBars ||
    (watermarkXArr?.length ?? 0) === 0 ||
    (watermarkYArr?.length ?? 0) === 0
      ? {}
      : {
          x: watermarkXArr[0].valueOf(),
          y: watermarkYArr[0],
          // same as above, these two formulas below are the product of repeated ui testing
          sizex:
            (watermarkXArr[watermarkXArr.length - 1].valueOf() -
              watermarkXArr[0].valueOf()) /
            watermarkInverseSize,
          sizey:
            (watermarkYArr[watermarkYArr.length - 1] - watermarkYArr[0]) /
            watermarkInverseSize,
          source: 'images/spotgamma-logo-full.png',
          xanchor: 'left',
          xref: 'x',
          yanchor: 'bottom',
          yref: 'y',
          layer: 'above',
          opacity: 0.5,
        };

  const plotlyData = getPlotlyData();

  const body = (
    <Loader isLoading={loading}>
      <Center>
        <ExpandableContentWrapper
          isOpenState={oiFullscreenState}
          styleOverrides={{ background: theme.palette.background.default }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box>
              {contourData == null ? (
                <Box sx={{ textAlign: 'center', marginTop: '25px' }}>
                  <Typography
                    sx={{ cursor: 'pointer' }}
                    onClick={fetchAllParquets}
                  >
                    There was an error. Please click here to retry.
                  </Typography>
                </Box>
              ) : (
                <Box
                  sx={{
                    backgroundColor: `${theme.palette.background.default} !important`,
                    marginTop: isMobile ? 0 : '5px',
                  }}
                >
                  <Box>
                    <TraceControls
                      timestamp={timestamp}
                      timestamps={timestamps}
                      productType={productType}
                      traceParams={traceParams}
                      zoomData={zoomData}
                      resetZoom={() => setZoomData(undefined)}
                      setStrikeBarType={(newType) => {
                        if (newType !== TraceStrikeBarType.NONE) {
                          if (
                            strikeBarParquetUrlForType(
                              newType,
                              intradayDate,
                              intradaySym,
                            ) !==
                            strikeBarParquetUrlForType(
                              strikeBarType,
                              intradayDate,
                              intradaySym,
                            )
                          ) {
                            // there is this very annoying lag where we set the label title first, then set the loading setting
                            // this is because of the slight lag caused by everything bubbling up into the useMemo/useEffects
                            // instead, setStrikeBarsLoading here if we anticipate needing to fetch
                            setStrikeBarsLoading(true);
                          }
                        }
                        saveSgSettings({ oi: { strikeBarType: newType } });
                      }}
                      showChartType={showChartType}
                      setShowChartType={setShowChartType}
                      statsData={statsDataMap.get(
                        getDateFormatted(intradayDate),
                      )}
                      gammaAtLastPriceUninverted={gammaAtLastPriceUninverted}
                      lastUpdateCheckAt={lastUpdateCheckAt}
                    />
                  </Box>
                  {isMobile && (
                    <Box
                      margin="auto"
                      width={1}
                      textAlign="center"
                      marginBottom="10px"
                    >
                      <ButtonGroup>
                        <Button
                          sx={{ fontSize: '13px', textTransform: 'none' }}
                          variant={
                            showChartType === TraceShowChartType.Heatmap
                              ? 'contained'
                              : 'outlined'
                          }
                          onClick={() =>
                            setShowChartType(TraceShowChartType.Heatmap)
                          }
                        >
                          Heatmap
                        </Button>
                        <Button
                          sx={{ fontSize: '13px', textTransform: 'none' }}
                          variant={
                            showChartType === TraceShowChartType.StrikeBars
                              ? 'contained'
                              : 'outlined'
                          }
                          onClick={() =>
                            setShowChartType(TraceShowChartType.StrikeBars)
                          }
                        >
                          Strike Bars
                        </Button>
                      </ButtonGroup>
                    </Box>
                  )}

                  <Box margin="auto">
                    <Plot
                      onRelayout={onZoom}
                      data={plotlyData.chartData}
                      onHover={onHover}
                      onUnhover={() => {
                        setHoverInfo(null);
                      }}
                      onClick={isMobile ? onHover : undefined}
                      config={{ showAxisDragHandles: false }}
                      layout={{
                        width,
                        height,
                        images: [watermark],
                        margin: {
                          l: 20,
                          r: isMobile ? 40 : 20,
                          b: isMobile ? 30 : 50,
                          t: 0,
                          pad: 4,
                        },
                        paper_bgcolor: theme.palette.background.default,
                        plot_bgcolor: theme.palette.background.default,
                        font: {
                          color: theme.palette.text.primary,
                        },
                        xaxis:
                          showChartType === TraceShowChartType.StrikeBars
                            ? {}
                            : {
                                rangeslider: {
                                  visible: false,
                                },
                                domain: [xAxisStartDomain, xAxisEndDomain],
                                fixedrange: false,
                                range: zoomData?.x,
                              },
                        xaxis2:
                          strikeBarType === TraceStrikeBarType.NONE ||
                          showChartType === TraceShowChartType.Heatmap
                            ? {}
                            : {
                                domain: xAxis2Domain,
                                range: strikeBarsRange,
                                ...(strikeBarsLoading
                                  ? {
                                      showline: false,
                                      zeroline: false,
                                      title: 'Updating. May take a minute...',
                                      showticklabels: false,
                                    }
                                  : {
                                      zeroline: showZeroLine,
                                      showline: false,
                                      title: {
                                        text: gexTitle(),
                                        font: {
                                          size: 12,
                                        },
                                      },
                                    }),
                              },
                        yaxis: {
                          // you must specify a y range otherwise there will be unwanted padding with the
                          // tracker scatter trace on OI/NET_OI
                          range: getYRange(zoomData, minY, maxY, strikeBarType),
                          fixedrange: false,
                          ...(shouldShowAxisLabels(width)
                            ? {
                                title: {
                                  text: 'Strike / Price ($)',
                                  standoff: width > 1050 ? 15 : 5,
                                },
                              }
                            : {}),
                        },
                        yaxis2:
                          hiroChartData == null
                            ? { fixedrange: false }
                            : {
                                fixedrange: false,
                                overlaying: 'y',
                                side: 'right',
                                position:
                                  xAxisEndDomain + (isMobile ? -0.01 : 0.005),
                                range:
                                  zoomData?.y2 ??
                                  getHiroRange(hiroChartData, priceBounds),
                                ...(shouldShowAxisLabels(width)
                                  ? {
                                      title: {
                                        text: 'HIRO',
                                      },
                                    }
                                  : {}),
                              },
                        showlegend: false,
                        annotations: plotlyData.annotations,
                        barmode: 'overlay',
                        // @ts-ignore
                        scattermode: 'overlay',
                        modebar: {
                          // remove all plotly buttons except the download as image one
                          // they simply dont work well with our chart
                          remove: [
                            'autoScale2d',
                            'hoverCompareCartesian',
                            'hovercompare',
                            'lasso2d',
                            'orbitRotation',
                            'pan2d',
                            'pan3d',
                            'resetCameraDefault3d',
                            'resetCameraLastSave3d',
                            'resetGeo',
                            'resetScale2d',
                            'resetViewMapbox',
                            'resetViews',
                            'select2d',
                            'sendDataToCloud',
                            'tableRotation',
                            'toggleHover',
                            'toggleSpikelines',
                            'togglehover',
                            'togglespikelines',
                            'zoom2d',
                            'zoom3d',
                            'zoomIn2d',
                            'zoomInGeo',
                            'zoomInMapbox',
                            'zoomOut2d',
                            'zoomOutGeo',
                            'zoomOutMapbox',
                            // remove toImage if mobile. if not, add dup key because ts complains
                            isMobile ? 'toImage' : 'zoom2d',
                          ],
                        },
                      }}
                    />
                  </Box>
                  {hoverInfo}
                  <Box>
                    <TraceTimeSlider
                      timestamps={timestamps}
                      chartWidth={width}
                      timestamp={timestamp}
                      setTimestamp={setTimestamp}
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </ExpandableContentWrapper>
      </Center>
    </Loader>
  );
  return (
    <Box sx={loading ? { width: 1, height: isMobile ? 1 : `${height}px` } : {}}>
      {body}
    </Box>
  );
};
