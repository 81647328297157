import * as d3 from 'd3';
import {
  Equity,
  GREEK_IDX,
  GreeksData,
  OPTION_IDX,
  RawGreeksObject,
  Scanner,
  ScannerColumnVisibility,
  ScannerExclusiveColumns,
  ScannerColumnKey,
  Tick,
} from '../types';
import { getDaysUntil, predicateSearch } from './shared';
import { getIVFromGreeks } from './iVol';
import {
  CROSS_ASSET_SUMMARY_SYMS,
  defaultColumnScannerVisibility,
} from '../config';

export const getEquitiesForScanner = (
  scanner: Scanner | undefined,
  data: Equity[],
): Equity[] => {
  if (!scanner) {
    return data;
  }

  switch (scanner) {
    case Scanner.MOST_CALL_GAMMA:
      // Sort on DESC Call Gamma
      return data.sort(
        (a: Equity, b: Equity) => Math.abs(b.atmgc) - Math.abs(a.atmgc),
      );
    case Scanner.MOST_PUT_GAMMA:
      // Sort on DESC Put Gamma
      return data.sort(
        (a: Equity, b: Equity) => Math.abs(b.atmgp) - Math.abs(a.atmgp),
      );
    case Scanner.LARGEST_DELTA:
      // Sort on DESC Total Delta
      return data
        .filter((d) => d.next_exp_g > 0.25)
        .sort((a: Equity, b: Equity) => b.totaldelta - a.totaldelta);
    case Scanner.CLOSE_TO_HEDGE_WALL:
      return data.filter(
        (d) => (d.upx - d.pws) / d.upx <= 0 && (d.upx - d.pws) / d.upx >= -0.01,
      );
    case Scanner.CLOSE_TO_KEY_DELTA_STRIKE:
      return data.filter(
        (d) =>
          (d.upx - d.keyd) / d.upx <= 0 && (d.upx - d.keyd) / d.upx >= -0.01,
      );
    case Scanner.HIGHEST_PUT_CALL_RATIO:
      // Sort on DESC put/call ratio
      return data.sort(
        (a: Equity, b: Equity) => b.putsum / b.callsum - a.putsum / a.callsum,
      );
    case Scanner.LOWEST_PUT_CALL_RATIO:
      // Sort on ASC put/call ratio
      return data.sort(
        (a: Equity, b: Equity) => a.putsum / a.callsum - b.putsum / b.callsum,
      );
    case Scanner.GAMMA_SQUEEZE:
      return data
        .filter(
          (d) =>
            d.callsum > 100000 &&
            d.cv > 100000 &&
            d.callsum > d.putsum &&
            d.cv > d.pv &&
            d.keyg > d.upx &&
            d.largeCoi > d.upx,
        )
        .sort((a: Equity, b: Equity) => a.sym.localeCompare(b.sym));
    case Scanner.HIGH_IMPACT:
      return data
        .filter(
          (d) =>
            d.pv > 25000 &&
            d.cv > 25000 &&
            d.callsum > 100000 &&
            d.putsum > 100000 &&
            d.next_exp_g > 0.3 &&
            getDaysUntil(d.max_exp_g_date) < 10,
        )
        .sort((a: Equity, b: Equity) => b.largeCoi - a.largeCoi);
    case Scanner.TOP_GAMMA_EXP:
      return data
        .filter(
          (d) => d.next_exp_g > 0.25 && getDaysUntil(d.max_exp_g_date) < 7,
        )
        .sort((a: Equity, b: Equity) => b.next_exp_g - a.next_exp_g);
    case Scanner.TOP_DELTA_EXP:
      return data
        .filter(
          (d) => d.next_exp_d > 0.25 && getDaysUntil(d.max_exp_d_date) < 7,
        )
        .sort((a: Equity, b: Equity) => b.next_exp_d - a.next_exp_d);
    case Scanner.BEARISH_DARK_POOL:
      return data.filter((d) => d.dpi && d.dpi < 0.3);
    case Scanner.BULLISH_DARK_POOL:
      return data.filter((d) => d.dpi && d.dpi > 0.6);
    case Scanner.VOL_RISK_PREMIUM:
      return data.filter((d) => !!d.vrp_scanner_high);
    case Scanner.SQUEEZE:
      return applySqueezeFilter(data);
    case Scanner.CROSS_ASSET_SUMMARY:
      const symsSet = new Set(CROSS_ASSET_SUMMARY_SYMS);
      return data
        .filter((d) => symsSet.has(d.sym))
        .sort(
          (a, b) =>
            CROSS_ASSET_SUMMARY_SYMS.indexOf(a.sym) -
            CROSS_ASSET_SUMMARY_SYMS.indexOf(b.sym),
        );
    default:
      return data;
  }
};

const applySqueezeFilter = (data: Equity[]) => {
  const excludedSyms = [
    'ARKK',
    'GDX',
    'GDXJ',
    'IBB',
    'ITB',
    'IWM',
    'IYE',
    'IYR',
    'KBE',
    'KRE',
    'KWEB',
    'MAGS',
    'MGK',
    'OIH',
    'QQQ',
    'SMH',
    'SPY',
    'SQQQ',
    'TQQQ',
    'VNQ',
    'XAR',
    'XBI',
    'XHB',
    'XLB',
    'XLC',
    'XLE',
    'XLI',
    'XLK',
    'XLP',
    'XLRE',
    'XLU',
    'XLV',
    'XME',
    'XOP',
    'XRT',
  ];

  return data.filter(
    (d) => !!d.squeeze_scanner && !excludedSyms.includes(d.sym),
  );
};

/** Returns values for callx1, callx2, putx1, putx2 */
export const gNotToPoints = (
  putGNot: number,
  callGNot: number,
  gammas: { call_gnot: number; put_gnot: number; price: number }[],
  ticks: Tick[],
) => {
  // Make max bar 30
  // Convert these to epoch millis
  const timestamps = ticks.map((d) => d.epoch_millis);
  const gnots = gammas
    .map((d) => [d.call_gnot, d.put_gnot, d.call_gnot + d.put_gnot])
    .flat();
  const [minGNot, maxGNot] = [Math.min(...gnots), Math.max(...gnots)];
  const timestampRange = Math.max(...timestamps) - Math.min(...timestamps);
  const [minTimestamp, maxTimestamp] = [
    Math.min(...timestamps),
    Math.min(...timestamps) + 0.25 * timestampRange,
  ];
  const scale = d3
    .scaleLinear()
    .domain([minGNot, maxGNot])
    .range([minTimestamp, maxTimestamp]);
  const baseline = scale(minGNot);

  return [
    baseline,
    Math.round(scale(callGNot)),
    Math.round(scale(callGNot)),
    Math.round(scale(callGNot + putGNot)),
  ];
};

export const priceToPoints = (
  price: number,
  gammas: { call_gnot: number; put_gnot: number; price: number }[],
  ticks: Tick[],
) => {
  const gammaPrices = gammas.map((d) => d.price);
  const tickPrices = ticks.map((d) => d.price);
  const prices = [...gammaPrices, ...tickPrices];

  const [minPrice, maxPrice] = [Math.min(...prices), Math.max(...prices)];
  const padding =
    (maxPrice - minPrice) / gammas.length / (gammas.length > 4 ? 3 : 6);
  return [price - padding, price + padding];
};

export const getIVForTgtDelta = (
  tgtDelta: number,
  dailyGreeks: RawGreeksObject,
) => {
  const allGreekEntries: [number, GreeksData][] = Object.entries(
    dailyGreeks,
  ).map(([strike, greeks]) => [Number(strike), greeks]);
  allGreekEntries.sort((a, b) => a[0] - b[0]);
  const normedDeltas = allGreekEntries.map(
    ([, callPut]: [number, GreeksData]) => {
      const callDelta = callPut[OPTION_IDX.CALL][GREEK_IDX.DELTA];
      const putDelta = callPut[OPTION_IDX.PUT][GREEK_IDX.DELTA];
      return callDelta <= 0.5 ? callDelta : 1 + putDelta;
    },
  );

  const idx = predicateSearch(normedDeltas, (d: number) => d > tgtDelta);
  const lowerIV = getIVFromGreeks(allGreekEntries[idx < 0 ? 0 : idx][1]);
  if (idx < 0 || idx + 1 >= normedDeltas.length) {
    return lowerIV;
  }
  const upperIV = getIVFromGreeks(allGreekEntries[idx + 1][1]);
  const upperDelta = normedDeltas[idx + 1];
  const fraction = (tgtDelta - upperDelta) / (normedDeltas[idx] - upperDelta);
  return d3.interpolateNumber(upperIV, lowerIV)(fraction);
};

export const getColumnsForScanner = (scanner: Scanner) =>
  Object.keys(defaultColumnScannerVisibility).reduce<ScannerColumnVisibility>(
    (acc, key) => {
      acc[key as ScannerColumnKey] = ScannerExclusiveColumns[scanner].includes(
        key as ScannerColumnKey,
      );
      return acc;
    },
    {} as ScannerColumnVisibility,
  );

export const createDummyEquity = (id: string): Equity => ({
  vrp_scanner_high: 1,
  squeeze_scanner: 1,
  callsum: Math.random() * 100,
  putsum: Math.random() * 100,
  minfs: Math.random() * 100,
  largeCoi: Math.random() * 100,
  largePoi: Math.random() * 100,
  totaldelta: Math.random() * 1000,
  d95ne: Math.random() * 100,
  d25ne: Math.random() * 100,
  d95: Math.random() * 100,
  d25: Math.random() * 100,
  strike_list: '',
  cg_list: '',
  pg_list: '',
  mf_list: '',
  smf_list: '',
  putctrl: Math.random() * 100,
  activity_factor: Math.random() * 100,
  position_factor: Math.random() * 100,
  call_strikes_list_absg: '',
  call_gnot_list_absg: '',
  put_strikes_list_absg: '',
  put_gnot_list_absg: '',
  date: new Date().toUTCString(),
  Symbol: id,
  [ScannerColumnKey.sym]: id,
  [ScannerColumnKey.tradeDate]: new Date().toUTCString(),
  [ScannerColumnKey.prevClose]: Math.random() * 100,
  [ScannerColumnKey.wkHigh52]: Math.random() * 100,
  [ScannerColumnKey.wkLow52]: Math.random() * 100,
  [ScannerColumnKey.putCallRatio]: Math.random() * 100,
  [ScannerColumnKey.keyGammaStr]: Math.random() * 100,
  [ScannerColumnKey.keyDeltaStr]: Math.random() * 100,
  [ScannerColumnKey.hedgeWall]: Math.random() * 100,
  [ScannerColumnKey.callWall]: Math.random() * 100,
  [ScannerColumnKey.putWall]: Math.random() * 100,
  [ScannerColumnKey.nextExpGamma]: Math.random() * 100,
  [ScannerColumnKey.nextExpDelta]: Math.random() * 100,
  [ScannerColumnKey.topGammaExp]: new Date().toUTCString(),
  [ScannerColumnKey.topDeltaExp]: new Date().toUTCString(),
  [ScannerColumnKey.putCallVolRatio]: Math.random() * 100,
  [ScannerColumnKey.callGamma]: Math.random() * 100,
  [ScannerColumnKey.putGamma]: Math.random() * 100,
  [ScannerColumnKey.gammaRatio]: Math.random(),
  [ScannerColumnKey.callDelta]: Math.random() * 100,
  [ScannerColumnKey.putDelta]: Math.random() * 100,
  [ScannerColumnKey.putVol]: Math.random() * 100,
  [ScannerColumnKey.callVol]: Math.random() * 100,
  [ScannerColumnKey.deltaRatio]: Math.random() * 100,
  [ScannerColumnKey.neSkew]: Math.random() * 100,
  [ScannerColumnKey.skew]: Math.random() * 100,
  [ScannerColumnKey.nextExpCallVol]: Math.random() * 100,
  [ScannerColumnKey.nextExpPutVol]: Math.random() * 100,
  [ScannerColumnKey.optionsImpliedMove]: Math.random() * 100,
  [ScannerColumnKey.volume]: Math.random() * 1000,
  [ScannerColumnKey.dpi]: Math.random(),
  [ScannerColumnKey.rv30]: Math.random(),
  [ScannerColumnKey.iv30]: Math.random() * 100,
  [ScannerColumnKey.skewRank]: Math.random(),
  [ScannerColumnKey.garchRank]: Math.random(),
  [ScannerColumnKey.ivRank]: Math.random(),
});

export const hasKeyEquityFields = (e: Equity) => {
  const keyFields: (keyof Equity)[] = [
    'name',
    ScannerColumnKey.sym,
    ScannerColumnKey.prevClose,
    ScannerColumnKey.volume,
  ];

  return keyFields.every((f) => !!e[f]);
};
