import NorthEastIcon from '@mui/icons-material/NorthEast';
import SouthWestIcon from '@mui/icons-material/SouthWest';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import {
  EquityColumnGroup,
  Scanner,
  ScannerColumnVisibility,
  ScannerColumnKey,
} from '../types';
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
import ExponentialGrowth from '../icons/ExponentialGrowth';

// TODO: Investigate fixing `putctrl`/Pivot calculation.  For now, suppressing it in
// the UX

export const FIELD_NAME_MAPPING = {
  upx: 'Last Closing',
  maxfs: 'Hedge Wall',
  keyg: 'Key Gamma Strike',
  keyd: 'Key Delta Strike',
  // putctrl: 'Pivot',
  cws: 'Call Wall',
  pws: 'Put Wall',
};

export const SCANNER_NAME_MAPPING: Record<Scanner, string> = {
  [Scanner.MOST_CALL_GAMMA]: 'Most Call Gamma',
  [Scanner.LOWEST_PUT_CALL_RATIO]: 'Lowest Put/Call Ratio',
  [Scanner.GAMMA_SQUEEZE]: 'Gamma Squeeze',
  [Scanner.MOST_PUT_GAMMA]: 'Most Put Gamma',
  [Scanner.HIGHEST_PUT_CALL_RATIO]: 'Highest Put/Call Ratio',
  [Scanner.CLOSE_TO_HEDGE_WALL]: '1% Margin of Hedge Wall',
  [Scanner.TOP_GAMMA_EXP]: 'Top Gamma % Expiring this Friday',
  [Scanner.TOP_DELTA_EXP]: 'Top Delta % Expiring this Friday',
  [Scanner.LARGEST_DELTA]: 'Largest Delta Positions',
  [Scanner.CLOSE_TO_KEY_DELTA_STRIKE]: '1% Margin of Key Delta Strike',
  [Scanner.HIGH_IMPACT]: 'High Impact',
  [Scanner.BEARISH_DARK_POOL]: 'Bearish Dark Pool Readings',
  [Scanner.BULLISH_DARK_POOL]: 'Bullish Dark Pool Readings',
  [Scanner.VOL_RISK_PREMIUM]: 'Volatility Risk Premium',
  [Scanner.SQUEEZE]: 'Squeeze',
  [Scanner.CROSS_ASSET_SUMMARY]: 'Cross Asset Summary',
};

export const SCANNER_DESCRIPTION_MAPPING: Record<Scanner, string> = {
  [Scanner.MOST_CALL_GAMMA]:
    'Call gamma can drive stocks higher. Watch for downside pressure following large call options expirations.',
  [Scanner.LOWEST_PUT_CALL_RATIO]:
    'Low put/call ratios can drive stocks higher. Watch for upside pressure following large put options expiration.',
  [Scanner.GAMMA_SQUEEZE]:
    'SpotGamma curates this list of stocks which may squeeze higher.',
  [Scanner.MOST_PUT_GAMMA]:
    'Put gamma can drive stocks lower. Watch for upside pressure following large put options expirations.',
  [Scanner.HIGHEST_PUT_CALL_RATIO]:
    'High put/call ratios can drive stocks lower. Watch for upside reversals following large put options expirations.',
  [Scanner.CLOSE_TO_HEDGE_WALL]:
    'Stocks may find options based support or resistance at this level, which could be a basis for a variety of different trades.',
  [Scanner.TOP_GAMMA_EXP]:
    'This level can act as a magnet, if a large position (x >=30% of gamma expiring). This magnet can release following expiration.',
  [Scanner.TOP_DELTA_EXP]:
    'This filter can indicate stocks which may reverse direction following upcoming options expirations (x > 30% of delta expiring).',
  [Scanner.LARGEST_DELTA]:
    'Similar to Top Delta %, the largest delta positions could continue to drive stocks in one direction and reverse following a large options expiration.',
  [Scanner.CLOSE_TO_KEY_DELTA_STRIKE]:
    'Stocks which are nearing a critical hedging level for dealers. This level could indicate a point of stock price acceleration or pinning.',
  [Scanner.HIGH_IMPACT]:
    'SpotGamma calculations indicate these stocks have options activity which is driving the price and therefore respect our levels.',
  [Scanner.BEARISH_DARK_POOL]:
    'Statistics show that a DPI reading under 30 is associated with a negative return of a stock over a 20 to 60 day period.',
  [Scanner.BULLISH_DARK_POOL]:
    'Statistics show that a DPI reading over 60 is associated with a positive return of a stock over a 5 to 60 day period.',
  [Scanner.VOL_RISK_PREMIUM]:
    'The Volatility Risk Premium scanner highlights names that have unusually expensive options, indicating potential opportunities to sell premium.',
  [Scanner.SQUEEZE]:
    'The Squeeze Scanner highlights stocks that have potential for an explosive upside move based on short interest, gamma levels, options volume, and a proprietary SpotGamma formula.',
  [Scanner.CROSS_ASSET_SUMMARY]:
    'Shows a list of symbols across stocks, bonds, and commodities to give you an overview of the current market dynamics across multiple asset classes.',
};

export const defaultColumnScannerVisibility = Object.values(
  ScannerColumnKey,
).reduce((acc, key) => {
  acc[key] = true;
  return acc;
}, {} as ScannerColumnVisibility);

export const SG_SPECIAL_SCANNERS = [
  {
    label: {
      icon: <FlashOnIcon fontSize="small" />,
      name: SCANNER_NAME_MAPPING[Scanner.VOL_RISK_PREMIUM],
      description: SCANNER_DESCRIPTION_MAPPING[Scanner.VOL_RISK_PREMIUM],
    },
    scanner: Scanner.VOL_RISK_PREMIUM,
  },
  {
    label: {
      icon: <ExponentialGrowth fontSize="small" />,
      name: SCANNER_NAME_MAPPING[Scanner.SQUEEZE],
      description: SCANNER_DESCRIPTION_MAPPING[Scanner.SQUEEZE],
    },
    scanner: Scanner.SQUEEZE,
  },
];

export const SCANNER_LIST = [
  {
    label: {
      name: 'Bullish Scanners',
      icon: <NorthEastIcon fontSize="small" />,
    },
    scanners: [
      {
        label: {
          name: SCANNER_NAME_MAPPING[Scanner.MOST_CALL_GAMMA],
          description: SCANNER_DESCRIPTION_MAPPING[Scanner.MOST_CALL_GAMMA],
        },
        scanner: Scanner.MOST_CALL_GAMMA,
      },
      {
        label: {
          name: SCANNER_NAME_MAPPING[Scanner.LOWEST_PUT_CALL_RATIO],
          description:
            SCANNER_DESCRIPTION_MAPPING[Scanner.LOWEST_PUT_CALL_RATIO],
        },
        scanner: Scanner.LOWEST_PUT_CALL_RATIO,
      },
      {
        label: {
          name: SCANNER_NAME_MAPPING[Scanner.GAMMA_SQUEEZE],
          description: SCANNER_DESCRIPTION_MAPPING[Scanner.GAMMA_SQUEEZE],
        },
        scanner: Scanner.GAMMA_SQUEEZE,
      },
      {
        label: {
          name: SCANNER_NAME_MAPPING[Scanner.BULLISH_DARK_POOL],
          description: SCANNER_DESCRIPTION_MAPPING[Scanner.BEARISH_DARK_POOL],
        },
        scanner: Scanner.BULLISH_DARK_POOL,
      },
    ],
  },
  {
    label: {
      name: 'Bearish Scanners',
      icon: <SouthWestIcon fontSize="small" />,
    },
    scanners: [
      {
        label: {
          name: SCANNER_NAME_MAPPING[Scanner.MOST_PUT_GAMMA],
          description: SCANNER_DESCRIPTION_MAPPING[Scanner.MOST_PUT_GAMMA],
        },
        scanner: Scanner.MOST_PUT_GAMMA,
      },
      {
        label: {
          name: SCANNER_NAME_MAPPING[Scanner.HIGHEST_PUT_CALL_RATIO],
          description:
            SCANNER_DESCRIPTION_MAPPING[Scanner.HIGHEST_PUT_CALL_RATIO],
        },
        scanner: Scanner.HIGHEST_PUT_CALL_RATIO,
      },
      {
        label: {
          name: SCANNER_NAME_MAPPING[Scanner.BEARISH_DARK_POOL],
          description: SCANNER_DESCRIPTION_MAPPING[Scanner.BEARISH_DARK_POOL],
        },
        scanner: Scanner.BEARISH_DARK_POOL,
      },
    ],
  },
  {
    label: {
      name: 'Variable Scanners',
      icon: <ShuffleIcon fontSize="small" />,
    },
    scanners: [
      {
        label: {
          name: SCANNER_NAME_MAPPING[Scanner.CLOSE_TO_HEDGE_WALL],
          description: SCANNER_DESCRIPTION_MAPPING[Scanner.CLOSE_TO_HEDGE_WALL],
        },
        scanner: Scanner.CLOSE_TO_HEDGE_WALL,
      },
      {
        label: {
          name: SCANNER_NAME_MAPPING[Scanner.TOP_GAMMA_EXP],
          description: SCANNER_DESCRIPTION_MAPPING[Scanner.TOP_GAMMA_EXP],
        },
        scanner: Scanner.TOP_GAMMA_EXP,
      },
      {
        label: {
          name: SCANNER_NAME_MAPPING[Scanner.TOP_DELTA_EXP],
          description: SCANNER_DESCRIPTION_MAPPING[Scanner.TOP_DELTA_EXP],
        },
        scanner: Scanner.TOP_DELTA_EXP,
      },
      {
        label: {
          name: SCANNER_NAME_MAPPING[Scanner.LARGEST_DELTA],
          description: SCANNER_DESCRIPTION_MAPPING[Scanner.LARGEST_DELTA],
        },
        scanner: Scanner.LARGEST_DELTA,
      },
      {
        label: {
          name: SCANNER_NAME_MAPPING[Scanner.CLOSE_TO_KEY_DELTA_STRIKE],
          description:
            SCANNER_DESCRIPTION_MAPPING[Scanner.CLOSE_TO_KEY_DELTA_STRIKE],
        },
        scanner: Scanner.CLOSE_TO_KEY_DELTA_STRIKE,
      },
      {
        label: {
          name: SCANNER_NAME_MAPPING[Scanner.HIGH_IMPACT],
          description: SCANNER_DESCRIPTION_MAPPING[Scanner.HIGH_IMPACT],
        },
        scanner: Scanner.HIGH_IMPACT,
      },
    ],
  },
  {
    label: {
      name: 'Thematic Overviews',
      icon: <ViewTimelineIcon fontSize="small" />,
    },
    scanners: [
      {
        label: {
          name: SCANNER_NAME_MAPPING[Scanner.CROSS_ASSET_SUMMARY],
          description: SCANNER_DESCRIPTION_MAPPING[Scanner.CROSS_ASSET_SUMMARY],
        },
        scanner: Scanner.CROSS_ASSET_SUMMARY,
      },
    ],
  },
];

export const COLUMN_TO_GROUP_MAPPING = {
  trade_date: EquityColumnGroup.STATISTICS,
  earnings_utc: EquityColumnGroup.STATISTICS,
  sym: EquityColumnGroup.STATISTICS,
  price: EquityColumnGroup.STATISTICS,
  upx: EquityColumnGroup.STATISTICS,
  total_volume: EquityColumnGroup.STATISTICS,
  keyg: EquityColumnGroup.SG_KEY_DAILY_LEVELS,
  keyd: EquityColumnGroup.SG_KEY_DAILY_LEVELS,
  maxfs: EquityColumnGroup.SG_KEY_DAILY_LEVELS,
  cws: EquityColumnGroup.SG_KEY_DAILY_LEVELS,
  pws: EquityColumnGroup.SG_KEY_DAILY_LEVELS,
  atmgc: EquityColumnGroup.SG_KEY_DAILY_LEVELS,
  atmgp: EquityColumnGroup.SG_KEY_DAILY_LEVELS,
  atmdc: EquityColumnGroup.SG_KEY_DAILY_LEVELS,
  atmdp: EquityColumnGroup.SG_KEY_DAILY_LEVELS,
  next_exp_g: EquityColumnGroup.SG_KEY_DAILY_LEVELS,
  next_exp_d: EquityColumnGroup.SG_KEY_DAILY_LEVELS,
  max_exp_g_date: EquityColumnGroup.SG_KEY_DAILY_LEVELS,
  max_exp_d_date: EquityColumnGroup.SG_KEY_DAILY_LEVELS,
  cv: EquityColumnGroup.SG_KEY_DAILY_LEVELS,
  pv: EquityColumnGroup.SG_KEY_DAILY_LEVELS,
  ne_call_volume: EquityColumnGroup.SG_KEY_DAILY_LEVELS,
  ne_put_volume: EquityColumnGroup.SG_KEY_DAILY_LEVELS,
  put_call_ratio: EquityColumnGroup.DIRECTIONAL_INDICATORS,
  volume_ratio: EquityColumnGroup.DIRECTIONAL_INDICATORS,
  gamma_ratio: EquityColumnGroup.DIRECTIONAL_INDICATORS,
  delta_ratio: EquityColumnGroup.DIRECTIONAL_INDICATORS,
  ne_skew: EquityColumnGroup.VOLATILITY_INSIGHTS,
  skew: EquityColumnGroup.VOLATILITY_INSIGHTS,
  rv30: EquityColumnGroup.VOLATILITY_INSIGHTS,
  atm_iv30: EquityColumnGroup.VOLATILITY_INSIGHTS,
  iv_rank: EquityColumnGroup.VOLATILITY_INSIGHTS,
  garch_rank: EquityColumnGroup.VOLATILITY_INSIGHTS,
  skew_rank: EquityColumnGroup.VOLATILITY_INSIGHTS,
  options_implied_move: EquityColumnGroup.VOLATILITY_INSIGHTS,
  dpi: EquityColumnGroup.DARK_POOL_INDICATORS,
  dpi_high52w: EquityColumnGroup.STATISTICS,
  dpi_low52w: EquityColumnGroup.STATISTICS,
  dpi_volume: EquityColumnGroup.DARK_POOL_INDICATORS,
  hist_dpi: EquityColumnGroup.DARK_POOL_INDICATORS,
  hist_dpi_volume: EquityColumnGroup.DARK_POOL_INDICATORS,
};

export const EQUITYHUB_TAB_ZENDESK_MAPPING: Record<string, string> = {
  composite: 'Composite View',
  pc_impact: 'Put & Call Impact',
  live_price: 'Live Price & SG Levels',
  skew: 'Skew Chart',
};

export const EQUITYHUB_UPSELL = {
  title: 'Unlock Unlimited Equity Hub Access',
  subtitle: "With a SpotGamma Pro subscription, you'll get:",
  items: [
    'Key levels for 3,500+ stocks & indices',
    'Personalized watchlist to monitor tickers',
    'Highly actionable alerts for options and equity traders',
  ],
};

// TODO: pull this from the middle layer so that changes are reflected more quickly
export const CROSS_ASSET_SUMMARY_SYMS = [
  'SPX',
  'NDX',
  'IWM',
  'ESX',
  'FXI',
  'VIX',
  'GLD',
  'SLV',
  'USO',
  'UNG',
  'URA',
  'HGA',
  'TLT',
  'HYG',
  'ARKK',
  'XLE',
  'XLF',
  'XLV',
  'XME',
];
