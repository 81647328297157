import { useRecoilState, useRecoilValue } from 'recoil';
import {
  equityHubSymbolsSetState,
  hiroSymbolsState,
  isMobileState,
  selectedHiroTabsState,
  userDetailsState,
} from '../../../../states';
import { Box, Button, Stack } from '@mui/material';
import { useCallback } from 'react';
import { useTheme } from '@mui/material/styles';
import { ProductType } from '../../../../types';
import { ColorMode } from '../../../../theme';
import { SymbolTab } from './SymbolTab';
import { readableProductType } from '../../../../util';
import useAuth from '../../../../hooks/auth/useAuth';
import { useSetSym } from '../../../../hooks';
import useUserDetails from '../../../../hooks/user/useUserDetails';

interface SymbolTabsProps {
  sym?: string;
  productType: ProductType;
}

const MAX_NUM_TABS = 30;

export const SymbolTabs = ({ sym, productType }: SymbolTabsProps) => {
  const { setSym, getSym } = useSetSym();
  const [hiroTabs, setHiroTabs] = useRecoilState(selectedHiroTabsState);
  const userDetails = useRecoilValue(userDetailsState);
  const theme = useTheme();
  const selectedSym = sym ?? getSym();
  const { saveSgSettings } = useUserDetails();
  const isMobile = useRecoilValue(isMobileState);
  const exceededMaxLength = hiroTabs.length >= MAX_NUM_TABS;
  const hiroSyms = useRecoilValue(hiroSymbolsState);
  const ehSyms = useRecoilValue(equityHubSymbolsSetState);
  const { productsWithAccess } = useAuth();

  const onClick = useCallback(
    (sym: string) => {
      setSym(sym, productType);
    },
    [setSym, productType],
  );

  const onDelete = useCallback(
    (sym: string) => {
      const newTabs = [...new Set(hiroTabs.filter((s: string) => s !== sym))];
      setHiroTabs(newTabs);
      saveSgSettings({ hiro: { tabs: newTabs } });
    },
    [hiroTabs, setHiroTabs, setSym, saveSgSettings, selectedSym],
  );

  const addTab = useCallback(() => {
    if (hiroTabs.length >= MAX_NUM_TABS) {
      return;
    }
    if (selectedSym != null && !hiroTabs.includes(selectedSym)) {
      const newTabs = [...new Set([...hiroTabs, selectedSym])];
      setHiroTabs(newTabs);
      saveSgSettings({ hiro: { tabs: newTabs } });
    }
  }, [hiroTabs, setHiroTabs, selectedSym, userDetails]);

  if (!productsWithAccess.has(productType)) {
    return null;
  }

  const productTypeHasSym = (sym: string) => {
    if (productType === ProductType.EQUITYHUB) {
      return ehSyms.has(sym);
    } else if (productType === ProductType.HIRO) {
      return hiroSyms.has(sym);
    }

    return true;
  };

  return (
    <Box
      display="flex"
      width="100%"
      paddingRight={productType === ProductType.HIRO ? '15px' : '5px'}
      justifyContent="space-between"
      mb="10px"
      sx={{
        '& .active.MuiChip-root': {
          backgroundColor: `#0e7167 !important`,
          color: '#fff !important',
        },
        '& .notActive.MuiChip-root': {
          backgroundColor: `transparent !important`,
          ...(theme.colorMode === ColorMode.LIGHT
            ? {
                'span, & .MuiChip-deleteIcon': {
                  color: `${theme.palette.text.primary}`,
                },
              }
            : {}),
        },
        '.MuiChip-deleteIcon': {
          fontSize: '18px !important',
          transition: 'all 0.2s ease',
          ':hover': {
            color: `${theme.palette.text.secondary} !important`,
          },
        },
        '& .active .MuiChip-deleteIcon': {
          color: 'rgba(255,255,255,0.3) !important',
          ':hover': {
            color: 'rgba(255,255,255,0.2) !important',
          },
        },
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        gap="7px"
        sx={{
          overflowX: 'auto',
          overflowY: 'hidden',
          width: '100%',
          paddingY: '8px',
          paddingLeft: '4px',
          paddingRight: '20px',
        }}
      >
        {hiroTabs.map((sym) => {
          const active = sym === selectedSym;
          const disabledMessage = productTypeHasSym(sym)
            ? undefined
            : `This symbol is not supported in ${readableProductType(
                productType,
              )}.`;
          return (
            <SymbolTab
              key={sym}
              symbol={sym}
              onDelete={onDelete}
              onClick={onClick}
              active={active}
              totalCount={hiroTabs.length}
              disabledMessage={disabledMessage}
            />
          );
        })}
      </Box>

      <Stack direction="row" alignItems="center" sx={{ paddingY: '8px' }}>
        {selectedSym != null && !hiroTabs.includes(selectedSym) && (
          <Button
            sx={{
              textTransform: 'none',
              fontSize: isMobile ? '11px' : '13px',
              height: '30px',
              whiteSpace: 'nowrap',
              textAlign: 'center',
            }}
            disabled={exceededMaxLength}
            onClick={addTab}
          >
            {exceededMaxLength ? 'Too many tabs' : `+ Add Tab`}
          </Button>
        )}
      </Stack>
    </Box>
  );
};
